import axiosInstance from '../../configurations/AxiosConfig.Commercial';

const getAdditionalItems = async (filtersProcessor) => {
	const request = `/v1/AdditionalItems`;
	return axiosInstance.get(request, { params: filtersProcessor });
};

const getAdditionalItemById = async (id) => {
	const request = `/v1/AdditionalItems/${id}`;
	return axiosInstance.get(request);
};

const patchAdditionalItem = async ({ id, price, translationEn = '', translationFr = '' }) => {
	const request = `/v1/AdditionalItems/${id}`;
	return axiosInstance.patch(request, [
		{
			op: 'replace',
			path: 'Price',
			value: price,
		},
		{
			op: 'replace',
			path: '/Languages/0',
			value: {
				cultureCode: 'en',
				value: translationEn,
			},
		},
		{
			op: 'replace',
			path: '/Languages/1',
			value: {
				cultureCode: 'fr',
				value: translationFr,
			},
		},
	]);
};

const AdditionalItemService = {
	getAdditionalItems,
	getAdditionalItemById,
	patchAdditionalItem,
};

export default AdditionalItemService;
