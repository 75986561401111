import axiosInstance from '../configurations/AxiosConfig';

const getNationalities = async () => {
	const request = `/Commons/Nationalities`;
	return axiosInstance.get(request);
};

const getCivilStatus = async () => {
	const request = `/Commons/CivilStatus`;
	return axiosInstance.get(request);
};

const getDrivingLicenses = async () => {
	const request = `/Commons/DrivingLicenses`;
	return axiosInstance.get(request);
};

const getPrincipalActivities = async () => {
	const request = `/Couriers/Activities`;
	return axiosInstance.get(request);
};

const getOthersActivities = async () => {
	const request = `/Couriers/OthersActivities`;
	return axiosInstance.get(request);
};

const getContractStatus = async () => {
	const request = `/Commons/ContractStatus`;
	return axiosInstance.get(request);
};

const getContractClients = async () => {
	const request = `/Commons/ContractClients`;
	return axiosInstance.get(request);
};

const getSituations = async () => {
	const request = `/Commons/Situations`;
	return axiosInstance.get(request);
};

const getShiftTypes = async () => {
	const request = `/Commons/ShiftTypes`;
	return axiosInstance.get(request);
};

const getContractTypes = async () => {
	const request = `/Commons/ContractTypes`;
	return axiosInstance.get(request);
};

const getContractReasonEnd = async () => {
	const request = `/Commons/ContractReasonEnd`;
	return axiosInstance.get(request);
};

const getAbsenceTypes = async () => {
	const request = `/Commons/AbsenceTypes`;
	return axiosInstance.get(request);
};

const getDocumentTypes = async () => {
	const request = `/Commons/DocumentTypes`;
	return axiosInstance.get(request);
};

const getDocumentStatus = async () => {
	const request = `/Commons/DocumentStatuts`;
	return axiosInstance.get(request);
};

const getAbsenceStatus = async () => {
	const request = `/Commons/AbsenceStatuts`;
	return axiosInstance.get(request);
};

const getRightTypes = async () => {
	const request = `/Profiles/RightTypes`;
	return axiosInstance.get(request);
};

const getCourierApplyStatus = async () => {
	const request = `/Commons/CourierApplyStatus`;
	return axiosInstance.get(request);
};

const getExtraTypes = async () => {
	const request = `/Commons/ExtraTypes`;
	return axiosInstance.get(request);
};

const getRightByRightTypeId = async (rightTypeId) => {
	const request = `/Profiles/RightsByRightType/${rightTypeId}`;
	return axiosInstance.get(request);
};

const getTeamLeaders = async (regionId) => {
	const request = `/Couriers/TeamLeaders?regionId=${regionId}`;
	return axiosInstance.get(request);
};

const getStatutInvitations = async () => {
	const request = `/Commons/StatutInvitations`;
	return axiosInstance.get(request);
};

const getTypeInvitations = async () => {
	const request = `/Commons/TypeInvitations`;
	return axiosInstance.get(request);
};

const getSwissCantons = async () => {
	const request = `/Commons/SwissCantons`;
	return axiosInstance.get(request);
};

const getStatutProcedures = async () => {
	const request = `/Commons/StatutProcedures`;
	return axiosInstance.get(request);
};

const getTeamLeaderById = async (idTeamLeader) => {
	const request = `/Couriers/TeamLeaders/${idTeamLeader}`;
	return axiosInstance.get(request);
};

const getTeamLeaderInfoTypes = async () => {
	const request = `/Commons/TeamLeaderInfoTypes`;
	return axiosInstance.get(request);
};

const getContractMotiveEnd = async () => {
	const request = `/Commons/MotiveContractEnd`;
	return axiosInstance.get(request);
};

const getVehicleTypes = async () => {
	const request = `/Commons/VehicleTypes`;
	return axiosInstance.get(request);
};

const getVehicleColors = async () => {
	const request = `/Commons/VehicleColors`;
	return axiosInstance.get(request);
};

const getSpouseActivities = async () => {
	const request = `/Commons/SpouseActivities`;
	return axiosInstance.get(request);
};

const getTownsByDepartmentId = async (areaParentId) => {
	const request = `/Commons/Towns/${areaParentId}`;
	return axiosInstance.get(request);
};

const getTowns = async () => {
	const request = `/Commons/Towns`;
	return axiosInstance.get(request);
};

const getDepartments = async () => {
	const request = `/Commons/Departments`;
	return axiosInstance.get(request);
};

const getWorkPermitApplieds = async () => {
	const request = `/Commons/WorkPermitApplieds`;
	return axiosInstance.get(request);
};

const CommonService = {
	getNationalities,
	getCivilStatus,
	getExtraTypes,
	getDrivingLicenses,
	getPrincipalActivities,
	getOthersActivities,
	getContractStatus,
	getContractClients,
	getContractTypes,
	getContractReasonEnd,
	getShiftTypes,
	getRightTypes,
	getRightByRightTypeId,
	getAbsenceTypes,
	getDocumentTypes,
	getDocumentStatus,
	getAbsenceStatus,
	getCourierApplyStatus,
	getTeamLeaders,
	getSituations,
	getStatutInvitations,
	getTypeInvitations,
	getSwissCantons,
	getStatutProcedures,
	getTeamLeaderById,
	getTeamLeaderInfoTypes,
	getContractMotiveEnd,
	getVehicleTypes,
	getVehicleColors,
	getSpouseActivities,
	getTownsByDepartmentId,
	getTowns,
	getDepartments,
	getWorkPermitApplieds,
};

export default CommonService;
