import { createAsyncThunk } from '@reduxjs/toolkit';
import { RETRIEVE_PRODUCTS, RETRIEVE_PRODUCT } from '../../../constants/Commercial/Products';
import ProductService from '../../../../services/Commercial/ProductService';

export const retrieveProducts = createAsyncThunk(RETRIEVE_PRODUCTS, async (filtersProcessor) => {
	const response = await ProductService.getProducts(filtersProcessor);
	return response.data;
});

export const retrieveProduct = createAsyncThunk(RETRIEVE_PRODUCT, async (id) => {
	const response = await ProductService.getProductById(id);

	return {
		...response.data,
		kilometerMinimum: response.data.kilometerMinimum / 1000,
		kilometerMaximum: response.data.kilometerMaximum / 1000,
		price: response.data.price / 100,
	};
});
