import axios from 'axios';
import LocalStorageService from './LocalStorageService';

const logInWithEmailAndPassword = async (email, password) => {
	const request = `${process.env.REACT_APP_BASEURL}/Authentications`;
	const model = {
		login: email,
		password,
	};
	const response = await axios.post(request, model);
	// Login successful
	// Set user
	LocalStorageService.setItem('auth_user', response.data);
	return response.data;
};

const logOut = () => {
	LocalStorageService.removeAll();
};

const refreshUserToken = async (refreshToken) => {
	const model = {
		accessToken: '',
		refreshToken,
	};
	const request = `${process.env.REACT_APP_BASEURL}/Authentications/Refresh`;
	return axios.post(request, model);
};

const checkValidationRefreshToken = async (refreshToken) => {
	const request = `${process.env.REACT_APP_BASEURL}/Authentications/CheckValidationRefreshToken?refreshToken=${refreshToken}`;
	return axios.get(request);
};

const checkValidationToken = async (token) => {
	const request = `${process.env.REACT_APP_BASEURL}/Authentications/CheckValidationToken`;
	const model = {
		accessToken: token,
		refreshToken: '',
	};
	return axios.post(request, model);
};

export {
	logInWithEmailAndPassword,
	logOut,
	refreshUserToken,
	checkValidationRefreshToken,
	checkValidationToken,
};
