import axiosInstance from '../configurations/AxiosConfig';

const getTaxScales = async () => {
	const request = `/Taxes/Scales`;
	return axiosInstance.get(request);
};
const getTaxChildCount = async () => {
	const request = `/Taxes/ChildCount`;
	return axiosInstance.get(request);
};
const getTaxCultTaxes = async () => {
	const request = `/Taxes/CultTaxOptions`;
	return axiosInstance.get(request);
};
const getTaxCodesPerCanton = async (cantonId) => {
	const request = `/Taxes/${cantonId}`;
	return axiosInstance.get(request);
};

const TaxService = {
	getTaxScales,
	getTaxChildCount,
	getTaxCultTaxes,
	getTaxCodesPerCanton,
};

export default TaxService;