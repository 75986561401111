import { createAsyncThunk } from '@reduxjs/toolkit';
import DistrictService from '../../../../services/Commercial/DistrictService';
import { RETRIEVE_DISTRICTS } from '../../../constants/Commercial/Districts';

// eslint-disable-next-line import/prefer-default-export
export const retrieveDistricts = createAsyncThunk(
	RETRIEVE_DISTRICTS,
	async () => {
		const response = await DistrictService.getDistricts();
		return response.data;
	},
);