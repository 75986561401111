import axiosInstance from '../configurations/AxiosConfig';

const getAgendas = async (filtersProcessor) => {
	const request = `/Agendas/Retrieve`;
	return axiosInstance.post(request, filtersProcessor);
};

const rejectCourierInvitation = async (idCourier) => {
	const request = `/Agendas/${idCourier}/RejectInvitation`;
	return axiosInstance.put(request);
};

const acceptCourierInvitation = async (idCourier) => {
	const request = `/Agendas/${idCourier}/AcceptInvitation`;
	return axiosInstance.put(request);
};

const AgendaService = {
	getAgendas,
	rejectCourierInvitation,
	acceptCourierInvitation,
};

export default AgendaService;
