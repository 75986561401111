import axios from 'axios';
import LocalStorageService from '../services/LocalStorageService';
import { refreshUserToken } from '../services/AuthenticationService';

export const responseSuccessHandler = (response) => {
	return response;
};

export const responseErrorHandler = async (error) => {
	if (error) {
		const userConnected = LocalStorageService.getItem('auth_user');
		const originalRequest = error.config;
		if (
			userConnected &&
			!originalRequest._retry &&
			(error.response.status === 401 || error.response.status === 403)
		) {
			originalRequest._retry = true;
			const { refreshToken } = userConnected;

			try {
				const responseToken = await refreshUserToken(refreshToken);
				// Token is valid
				LocalStorageService.setItem('auth_user', responseToken.data);
				return axiosInstance(originalRequest);
			} catch (_error) {
				LocalStorageService.removeAll();
				const tokenError = new Error('Cannot refresh token');
				tokenError.originalError = error;
				return Promise.reject(tokenError);
			}
		}
	}
	return Promise.reject(error);
};

export const requestSuccessHandler = async (config) => {
	const userConnected = LocalStorageService.getItem('auth_user');
	if (userConnected) {
		config.headers = {
			Authorization: `bearer ${userConnected.accessToken}`,
			Accept: 'application/json',
			'Content-Type': 'application/json-patch+json',
		};
	}
	return config;
};

export const requestErrorHandler = (error) => {
	Promise.reject(error);
};

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASEURL,
});

axiosInstance.interceptors.request.use(requestSuccessHandler, requestErrorHandler);

axiosInstance.interceptors.response.use(
	(response) => responseSuccessHandler(response),
	(error) => responseErrorHandler(error),
);
export default axiosInstance;
