import { createSlice } from '@reduxjs/toolkit';
import { retrieveAgendas } from '../../actions/Agendas';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	agendas: [],
	totalAgendas: 0,
};

/**
 * Le slide ou reducers de la gestion des agendas
 */
export const agendasSlice = createSlice({
	name: 'agendas',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrieveAgendas.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveAgendas.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.agendas = [...action.payload.data];
			state.totalAgendas = action.payload.count;
		},
		[retrieveAgendas.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const agendasSelector = (state) => state.agendas;

/**
 * Export du reducer
 */
const { reducer } = agendasSlice;
export default reducer;
