import { createSlice } from '@reduxjs/toolkit';
import {
	retrieveCourierPlannings,
	retrieveCourierAbsences,
	retrieveCourierExtras,
	retrieveCourierMessages,
} from '../../actions/Couriers';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	planningEvent: [],
	messages: [],
};

const addToList = (state, action) => {
	state.isLoading = false;
	state.isError = false;
	state.isSuccess = true;
	action?.payload?.forEach((element) => {
		const check = state.planningEvent?.find(
			(item) => item.start === element.start && item.end === element.end,
		);
		if (check === undefined) {
			state.planningEvent?.push(element);
		}
	});
};

/**
 * Le slide ou reducers de la gestion des coursiers
 */
export const courierSlice = createSlice({
	name: 'courier',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrieveCourierPlannings.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveCourierPlannings.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.planningEvent = [];
			state.planningEvent = [...action.payload];
		},
		[retrieveCourierMessages.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.messages = [...action.payload.data];
		},
		[retrieveCourierAbsences.fulfilled]: (state, action) => {
			addToList(state, action);
		},
		[retrieveCourierExtras.fulfilled]: (state, action) => {
			addToList(state, action);
		},
		[retrieveCourierPlannings.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
		[retrieveCourierMessages.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const courierSelector = (state) => state.courier;

/**
 * Export du reducer
 */
const { reducer } = courierSlice;
export default reducer;
