import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	RETRIEVE_PROFILES,
	RETRIEVE_PROFILES_LIST,
	RETRIEVE_ORGANIZATIONS_LIST,
	CREATE_PROFILE,
	UPDATE_PROFILE,
	RETRIEVE_PROFILE,
} from '../../constants/Profiles';
import ProfileService from '../../../services/ProfileService';

export const retrieveProfiles = createAsyncThunk(
	RETRIEVE_PROFILES,
	async ({ filtersProcessor, filter }) => {
		const response = await ProfileService.getProfiles(filtersProcessor, filter);
		return response.data;
	},
);

export const retrieveProfilesList = createAsyncThunk(RETRIEVE_PROFILES_LIST, async () => {
	const response = await ProfileService.getProfilesList();
	return response.data;
});

export const retrieveOrganizationsList = createAsyncThunk(RETRIEVE_ORGANIZATIONS_LIST, async () => {
	const response = await ProfileService.getOrganizationList();
	return response.data;
});


export const createProfile = createAsyncThunk(CREATE_PROFILE, async ({ profileModel }) => {
	const response = await ProfileService.createProfile(profileModel);
	return response.data;
});

export const updateProfile = createAsyncThunk(
	UPDATE_PROFILE,
	async ({ profileId, profileModel }) => {
		const response = await ProfileService.updateProfile(profileId, profileModel);
		return response.data;
	},
);

export const getProfileById = createAsyncThunk(RETRIEVE_PROFILE, async (profileId) => {
	const response = await ProfileService.getProfileById(profileId);
	return response.data;
});
