import axiosInstance from '../../configurations/AxiosConfig.Commercial';

const getVolumes = async (filtersProcessor) => {
	const request = `/v1/Volumes`;
	return axiosInstance.get(request, { params: filtersProcessor });
};

const getVolumeById = async (id) => {
	const request = `/v1/Volumes/${id}`;
	return axiosInstance.get(request);
};

const VolumeService = {
	getVolumes,
	getVolumeById,
};

export default VolumeService;
