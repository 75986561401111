import { createSlice } from '@reduxjs/toolkit';
import { retrieveVehicles } from '../../../actions/Commercial/Vehicles';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	vehicles: [],
	totalVehicles: 0,
};

/**
 * Le slice ou reducers de la gestion des véhicules
 */
export const vehiclesSlice = createSlice({
	name: 'vehicles',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrieveVehicles.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveVehicles.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.vehicles = [...action.payload.items];
			state.totalVehicles = action.payload.totalCount;
		},
		[retrieveVehicles.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const vehiclesSelector = (state) => state.commercialVehicles;

/**
 * Export du reducer
 */
const { reducer } = vehiclesSlice;
export default reducer;
