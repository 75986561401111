import { createSlice } from '@reduxjs/toolkit';
import { retrieveDistricts } from '../../../actions/Commercial/Districts';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	districts: [],
	totaldistricts: 0,
};

/**
 * Le slice ou reducers de la gestion des districts
 */
export const districtsSlice = createSlice({
	name: 'districts',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrieveDistricts.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveDistricts.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.districts = [...action.payload.items];
			state.totaldistricts = action.payload.totalCount;
		},
		[retrieveDistricts.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const districtsSelector = (state) => state.commercialDistricts;

/**
 * Export du reducer
 */
const { reducer } = districtsSlice;
export default reducer;
