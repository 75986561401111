import couriersReducer from './slices/Couriers';
import courierReducer from './slices/Courier';
import commonsReducer from './slices/Commons';
import shiftsReducer from './slices/Shifts';
import AdministratorsReducer from './slices/Administrators';
import ProfilesReducer from './slices/Profiles';
import AbsencesReducer from './slices/Absences';
import ExtrasReducer from './slices/Extras';
import ProceduresReducer from './slices/Procedures';
import AgendasReducer from './slices/Agendas';
import AdditionalItemsReducer from './slices/Commercial/AdditionalItems';
import AdditionalItemReducer from './slices/Commercial/AdditionalItem';
import VehiclesReducer from './slices/Commercial/Vehicles';
import OrdersReducer from './slices/Commercial/Orders';
import PostalCodesReducer from './slices/Commercial/PostalCodes';
import PostalCodeReducer from './slices/Commercial/PostalCode';
import ProductsReducer from './slices/Commercial/Products';
import ProductReducer from './slices/Commercial/Product';
import DistrictsReducer from './slices/Commercial/Districts';
import WeightsRecuder from './slices/Commercial/Weights';
import VolumesReducer from './slices/Commercial/Volumes';

const reducer = {
	couriers: couriersReducer,
	courier: courierReducer,
	commons: commonsReducer,
	shifts: shiftsReducer,
	administrators: AdministratorsReducer,
	profiles: ProfilesReducer,
	absences: AbsencesReducer,
	extras: ExtrasReducer,
	procedures: ProceduresReducer,
	agendas: AgendasReducer,
	commercialAdditionalItems: AdditionalItemsReducer,
	commercialAdditionalItem: AdditionalItemReducer,
	commercialVehicles: VehiclesReducer,
	commercialOrders: OrdersReducer,
	commercialPostalCodes: PostalCodesReducer,
	commercialPostalCode: PostalCodeReducer,
	commercialProducts: ProductsReducer,
	commercialProduct: ProductReducer,
	commercialDistricts: DistrictsReducer,
	commercialWeights: WeightsRecuder,
	commercialVolumes: VolumesReducer,
};

export default reducer;
