import axiosInstance from '../configurations/AxiosConfig';

const getCourierApplies = async (filtersProcessor) => {
	const request = `/CourierApplies/Retrieve`;
	return axiosInstance.post(request, filtersProcessor);
};

const getCourierApplyById = async (courierId) => {
	const request = `/CourierApplies/${courierId}`;
	return axiosInstance.get(request);
};

const sendRejectionToCourierApplyById = async (courierId) => {
	const request = `/CourierApplies/${courierId}/RejectApplication`;
	return axiosInstance.get(request);
};

const sendRejectionToMultipleApplicants = async (courierIds) => {
	const response = await axiosInstance.post('/CourierApplies/RejectApplications', courierIds);
	return response.data;
};

const sendAcceptationToCourierApplyById = async (courierId) => {
	const request = `/CourierApplies/${courierId}/AcceptApplication`;
	return axiosInstance.get(request);
};

const sendAcceptationToMultipleAppilcants = async (courierIds) => {
	const response = await axiosInstance.post('/CourierApplies/AcceptApplications', courierIds);
	return response.data;
};

const deleteCourierApplyById = async (courierId) => {
	const request = `/CourierApplies/${courierId}`;
	return axiosInstance.delete(request);
};

const deleteMultipleCourierApplications = async (courierIds) => {
	const response = await axiosInstance.post('/CourierApplies/DeleteApplications', courierIds);
	return response.data;
};

const updateCourierApply = async (idCourier, courierModel) => {
	const request = `/CourierApplies/${idCourier}`;
	return axiosInstance.put(request, courierModel);
};

const inviteCourier = async (idCourier, invitationModel) => {
	const request = `/CourierApplies/${idCourier}/InviteCourier`;
	return axiosInstance.post(request, invitationModel);
};

const exportCouriersApply = async (searchExportFilter) => {
	const request = `/CourierApplies/Export`;
	return axiosInstance.post(request, searchExportFilter, { responseType: 'blob' });
};
const CourierApplyService = {
	getCourierApplies,
	getCourierApplyById,
	sendRejectionToCourierApplyById,
	sendRejectionToMultipleApplicants,
	sendAcceptationToCourierApplyById,
	sendAcceptationToMultipleAppilcants,
	deleteCourierApplyById,
	deleteMultipleCourierApplications,
	updateCourierApply,
	inviteCourier,
	exportCouriersApply,
};

export default CourierApplyService;
