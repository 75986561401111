import { createAsyncThunk } from '@reduxjs/toolkit';
import WeightService from '../../../../services/Commercial/WeightService';
import { RETRIEVE_WEIGHT, RETRIEVE_WEIGHTS } from '../../../constants/Commercial/Weights';

export const retrieveWeights = createAsyncThunk(RETRIEVE_WEIGHTS, async (filtersProcessor) => {
	const response = await WeightService.getWeights(filtersProcessor);
	return response.data;
});

export const retrieveWeight = createAsyncThunk(RETRIEVE_WEIGHT, async (id) => {
	const response = await WeightService.getWeightById(id);
	return response.data;
});
