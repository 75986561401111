import React, { lazy, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import contents from '../../routes/contentRoutes';
import LocalStorageService from '../../services/LocalStorageService';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));

const ContentRoutes = () => {
	const [contentRoutesCan, setContentRoutesCan] = useState(contents);

	useEffect(() => {
		const userConnected = LocalStorageService.getItem('auth_user');
		if (userConnected) {
			const decodedToken = jwt_decode(userConnected?.accessToken);
			const userRights = decodedToken?.role;

			const list = contents?.filter(
				(route) => !route.right || userRights?.includes(route.right),
			);
			setContentRoutesCan(list);
		}
	}, []);

	return (
		<Routes>
			{contentRoutesCan?.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
