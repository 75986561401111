import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	CREATE_ADMINISTRATOR,
	RETRIEVE_ADMINISTRATORS,
	RETRIEVE_ADMINISTRATOR,
	UPDATE_ADMINISTRATOR,
} from '../../constants/Administrators';
import AdministratorService from '../../../services/AdministratorService';

export const createAdministrator = createAsyncThunk(
	CREATE_ADMINISTRATOR,
	async ({ administratorModel }) => {
		const response = await AdministratorService.createAdministrator(administratorModel);
		return response.data;
	},
);

export const retrieveAdministrators = createAsyncThunk(
	RETRIEVE_ADMINISTRATORS,
	async (filtersProcessor) => {
		const response = await AdministratorService.getAdministrators(filtersProcessor);
		return response.data;
	},
);

export const updateAdministrator = createAsyncThunk(
	UPDATE_ADMINISTRATOR,
	async ({ administratorId, administratorModel }) => {
		const response = await AdministratorService.updateAdministrator(
			administratorId,
			administratorModel,
		);
		return response.data;
	},
);

export const getAdministratorById = createAsyncThunk(
	RETRIEVE_ADMINISTRATOR,
	async (administratorId) => {
		const response = await AdministratorService.getAdministratorById(administratorId);
		return response.data;
	},
);
