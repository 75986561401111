import axiosInstance from '../configurations/AxiosConfig';

const getCouriers = async (filtersProcessor) => {
	const request = `/Couriers/Retrieve`;
	return axiosInstance.post(request, filtersProcessor);
};

const getActifCouriers = async (filter, regions) => {
	const request = `/Couriers/ActifCouriers?filter=${filter}${regions
		.map((id) => `&regionIds=${id}`)
		.join('')}`;
	return axiosInstance.get(request);
};

const exportCouriers = async (searchExportFilter) => {
	const request = `/Couriers/Export`;
	return axiosInstance.post(request, searchExportFilter, { responseType: 'blob' });
};

const getCourierById = async (courierId) => {
	const request = `/Couriers/${courierId}`;
	return axiosInstance.get(request);
};

const deleteCourierById = async (courierId) => {
	const request = `/Couriers/${courierId}`;
	return axiosInstance.delete(request);
};

const getCourierPlannings = async (courierId, type, date) => {
	const request = `/Couriers/${courierId}/Plannings?type=${type}&date=${date.toISOString()}`;
	return axiosInstance.get(request);
};

const getCourierMessages = async (courierId, filtersProcessor, filter) => {
	const request = `/Couriers/${courierId}/Messages?filter=${filter}`;
	return axiosInstance.post(request, filtersProcessor);
};

const getCourierAbsences = async (courierId, type, date) => {
	const request = `/Couriers/${courierId}/Absences?type=${type}&date=${date.toISOString()}`;
	return axiosInstance.get(request);
};

const getCourierExtras = async (courierId, type, date) => {
	const request = `/Couriers/${courierId}/Extras?type=${type}&date=${date}`;
	return axiosInstance.get(request);
};

const getCourierContractsById = async (courierId) => {
	const request = `/Couriers/${courierId}/Contracts`;
	return axiosInstance.get(request);
};

const createCourier = async (courierModel) => {
	const request = `/Couriers`;
	return axiosInstance.post(request, courierModel);
};

const updateCourier = async (idCourier, courierModel) => {
	const request = `/Couriers/${idCourier}`;
	return axiosInstance.put(request, courierModel);
};

const updateCourierState = async (emailCourier, courierModel) => {
	const request = `/Couriers/state/${emailCourier}`;
	return axiosInstance.put(request, courierModel);
};

const renewCourierAccount = async (idCourier) => {
	const request = `/Couriers/${idCourier}/RenewAccount`;
	return axiosInstance.put(request);
};

const checkCourierSituation = async (courierId, situationId) => {
	const request = `/Couriers/${courierId}/CheckCourierSituation?situationId=${situationId}`;
	return axiosInstance.get(request);
};

const updateCourierProfileImage = async (courierId, profileImageFile) => {
	const request = `/Couriers/${courierId}/ProfileImage`;
	return axiosInstance.put(request, profileImageFile, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

const checkCourierHasComments = async (courierId) => {
	const request = `/Couriers/${courierId}/HasComments`;
	return axiosInstance.get(request);
};

const CourierService = {
	getCouriers,
	getActifCouriers,
	exportCouriers,
	getCourierById,
	getCourierContractsById,
	getCourierMessages,
	getCourierPlannings,
	getCourierAbsences,
	getCourierExtras,
	createCourier,
	updateCourier,
	updateCourierState,
	deleteCourierById,
	renewCourierAccount,
	checkCourierSituation,
	updateCourierProfileImage,
	checkCourierHasComments,
};

export default CourierService;
