import { createSlice } from '@reduxjs/toolkit';
import { retrieveAdditionalItems } from '../../../actions/Commercial/AdditionalItems';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	additionalItems: [],
	totalAdditionalItems: 0,
};

/**
 * Le slice ou reducers de la gestion des additionalItems
 */
export const additionalItemsSlice = createSlice({
	name: 'additionalItems',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrieveAdditionalItems.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveAdditionalItems.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.additionalItems = [...action.payload.items];
			state.totalAdditionalItems = action.payload.totalCount;
		},
		[retrieveAdditionalItems.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const additionalItemsSelector = (state) => state.commercialAdditionalItems;

/**
 * Export du reducer
 */
const { reducer } = additionalItemsSlice;
export default reducer;
