import axiosInstance from '../../configurations/AxiosConfig.Commercial';

const getDistricts = async () => {
	const request = `/v1/Districts`;
	return axiosInstance.get(request);
};

const DistrictService = {
	getDistricts,
};

export default DistrictService;