import axiosInstance from '../configurations/AxiosConfig';

const getMarketplaceExtras = async (filtersProcessor) => {
	const request = `/Extras/RetrieveMarketPlace`;
	return axiosInstance.post(request, filtersProcessor);
};

const getExtrasTaken = async (filtersProcessor) => {
	const request = `/Extras/RetrieveExtrasTaken`;
	return axiosInstance.post(request, filtersProcessor);
};

const createLinkBetweenExtraCourier = async (extraName, courierId, region, start) => {
	const request = `/Extras/LinkExtraToCourier?extraName=${encodeURIComponent(
		extraName,
	)}&courierId=${courierId}&regionId=${region}&start=${start}`;
	return axiosInstance.post(request);
};

const createExtra = async (extraModel) => {
	const request = `/Extras`;
	return axiosInstance.post(request, extraModel);
};

const createExtraUsingShift = async (extraModel) => {
	const request = `/Extras/UsingShift`;
	return axiosInstance.post(request, extraModel);
};

const deleteExtra = async (extraName, start, region) => {
	const request = `/Extras?extraName=${encodeURIComponent(
		extraName,
	)}&start=${start}&regionId=${region}`;
	return axiosInstance.delete(request);
};

const getExtrasStatistics = async (date, region) => {
	const request = `/Extras/Statistiques?date=${date}&regionId=${region}`;
	return axiosInstance.get(request);
};

const getAnalyzer = async (date, region) => {
	const request = `/Extras/Analyzer?date=${date}&regionId=${region}`;
	return axiosInstance.get(request);
};

const exportExtrasLinkToCourier = async (searchExportFilter) => {
	const request = `/Extras/Export`;
	return axiosInstance.post(request, searchExportFilter, { responseType: 'blob' });
};

const ExtraService = {
	getMarketplaceExtras,
	getExtrasTaken,
	createLinkBetweenExtraCourier,
	deleteExtra,
	createExtraUsingShift,
	createExtra,
	getExtrasStatistics,
	getAnalyzer,
	exportExtrasLinkToCourier,
};

export default ExtraService;
