import React from 'react';
import { useMeasure } from 'react-use';
import classNames from 'classnames';
import useDarkMode from '../../hooks/useDarkMode';
import Badge from '../../components/bootstrap/Badge';

const Footer = () => {
	const now = new Date();

	const [ref, { height }] = useMeasure();

	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);

	const { darkModeStatus } = useDarkMode();

	/**
	 *Cette méthode permet retourner la couleur du badge en fonction de l'environnement
	 *
	 * @param {*} envName
	 * @return {*}
	 */
	const getBadgeEnvironmentColor = (envName) => {
		switch (envName) {
			case 'Developpement':
			case 'developpement':
				return 'secondary';
			case 'Qualification':
			case 'qualification':
				return 'primary';
			case 'Production':
			case 'production':
				return 'danger';
			default:
				return 'dark';
		}
	};

	return (
		<footer ref={ref} className='footer'>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						<span className='fw-light'>
							Copyright © {now.getFullYear()} - Version{' '}
							{process.env.REACT_APP_VERSION}{' '}
							<Badge
								key='ModeEnvironnement'
								color={getBadgeEnvironmentColor(
									process.env.REACT_APP_MODE_ENVIRONNEMENT,
								)}
								isLight>
								{process.env.REACT_APP_MODE_ENVIRONNEMENT}
							</Badge>
						</span>
					</div>
					<div className='col-auto'>
						<a
							href='/'
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}>
							<small className='fw-bold'>Chaskis Back Office</small>
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
