import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	RETRIEVE_EXTRAS_MARKETPLACE,
	RETRIEVE_EXTRAS_TAKEN,
	RETRIEVE_EXTRAS_STATISTICS,
} from '../../constants/Extras';
import ExtraService from '../../../services/ExtraService';

export const retrieveExtrasMarketplace = createAsyncThunk(
	RETRIEVE_EXTRAS_MARKETPLACE,
	async (filtersProcessor) => {
		const response = await ExtraService.getMarketplaceExtras(filtersProcessor);
		return response.data;
	},
);

export const retrieveExtrasTaken = createAsyncThunk(
	RETRIEVE_EXTRAS_TAKEN,
	async (filtersProcessor) => {
		const response = await ExtraService.getExtrasTaken(filtersProcessor);
		return response.data;
	},
);

export const retrieveExtrasStatistics = createAsyncThunk(
	RETRIEVE_EXTRAS_STATISTICS,
	async ({ date, region }) => {
		const response = await ExtraService.getExtrasStatistics(date, region);
		return response.data;
	},
);
