import axiosInstance from '../../configurations/AxiosConfig.Commercial';

const getProducts = async (filtersProcessor) => {
	const request = `/v1/Products`;
	return axiosInstance.get(request, { params: filtersProcessor });
};

const getProductById = async (id) => {
	const request = `/v1/Products/${id}`;
	return axiosInstance.get(request);
};

const patchProduct = async ({ id, price }) => {
	const request = `/v1/Products/${id}`;
	return axiosInstance.patch(request, [
		{
			op: 'replace',
			path: 'Price',
			value: price,
		},
	]);
};

const ProductService = {
	getProducts,
	getProductById,
	patchProduct,
};

export default ProductService;
