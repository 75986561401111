import moment from 'moment';
import localStorageService from '../services/LocalStorageService';

export function test() {
	return null;
}

export function getActionCrud(action) {
	switch (action) {
		case 'c':
			return { isCreate: true, isUpdate: false, isDelete: false, isRead: false };
		case 'u':
			return { isCreate: false, isUpdate: true, isDelete: false, isRead: false };
		case 'd':
			return { isCreate: false, isUpdate: false, isDelete: true, isRead: false };
		case 'r':
			return { isCreate: false, isUpdate: false, isDelete: false, isRead: true };
		default:
			return { isCreate: false, isUpdate: false, isDelete: false, isRead: false };
	}
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs) => {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const HourMinuteFormat = (date) => {
	if (date.length === 5 && date.includes(':')) {
		return date;
	}
	return moment(date).format('HH:mm');
};

export const DateFormat = (date) => {
	return moment(date).format('DD-MM-YYYY');
};

export const DateFormatYearMonthDay = (date) => {
	return moment(date).format('YYYY-MM-DD');
};

export const DateHourMinuteSecondFormat = (date) => {
	return moment(date).format('YYYY-MM-DDTHH:mm:ss');
};

export const DateHourMinuteSecondFormat2 = (date) => {
	return moment(date).format('DD/MM/YYYY HH:mm:ss');
};

export const DateHourMinuteFormat = (date) => {
	return moment(date).format('YYYY-MM-DDTHH:mm');
};

export const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1, value2) => ((value1 / value2 - 1) * 100).toFixed(2);

export const getFirstLetter = (text, letterCount = 2) =>
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func, wait = 1000) => {
	let timeout;

	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

/**
 * Cette méthode permet de télécharger un fichier excel
 *
 * @param {object[]} data : données
 * @param {string} filename : le nom du fichier
 * @param {string} extension : extension du fichier
 */
export const download = (data, filename, extension) => {
	const url = window.URL.createObjectURL(new Blob([data]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', formatFilename(filename, extension));

	// Append to html link element page
	document.body.appendChild(link);

	// Start download
	link.click();

	// Clean up and remove the link
	link.parentNode.removeChild(link);
};

export const formatFilename = (name, extension) => {
	const now = new Date();
	return `${name.replace(
		/\s+/g,
		'',
	)}-${now.getMonth()}-${now.getDay()}_${now.getHours()}-${now.getMinutes()}.${extension}`;
};

export const getApiSorting = (sort) => {
	return sort.map((s) => {
		return {
			memberName: `${s.columnName}`,
			sortDirection: s.direction === 'asc' ? 0 : 1,
		};
	});
};

export const getPageFilters = () => {
	const pageFilters = localStorageService.getItem('pageFilters');
	return pageFilters || {};
};

export const setPageFilters = (pageFilters) => {
	localStorageService.setItem('pageFilters', pageFilters);
};

export const getPageFiltersByPage = (pageName) => {
	const pageFilters = getPageFilters();
	return pageFilters[pageName];
};

export const setPageFiltersByPage = (pageName, filters) => {
	const pageFilters = getPageFilters();
	pageFilters[pageName] = filters;
	setPageFilters(pageFilters);
};

export const getCurrentFilters = (gridName, filters) => {
	const value = localStorageService.getItem(`grid_filters_${gridName}`);
	return value || filters;
};

export const setCurrentFilters = (gridName, filters) => {
	localStorageService.setItem(`grid_filters_${gridName}`, filters);
};

export const getCurrentPageSize = (defaultValue) => {
	const value = localStorageService.getItem('grid_pagesize');
	return value || defaultValue;
};

export const setCurrentPageSize = (value) => {
	localStorageService.setItem('grid_pagesize', value);
};

export const isEmpty = (value) => {
	return value == null || (typeof value === 'string' && value.trim().length === 0);
};
