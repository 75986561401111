import axiosInstance from '../configurations/AxiosConfig';

const getShifts = async (filtersProcessor) => {
	const request = `/Shifts/Retrieve`;
	return axiosInstance.post(request, filtersProcessor);
};

const getShiftsList = async (filter, regionId) => {
	const request = `/Shifts?filter=${encodeURIComponent(filter)}&regionId=${regionId}`;
	return axiosInstance.get(request);
};

const createShift = async (shiftModel) => {
	const request = `/Shifts`;
	return axiosInstance.post(request, shiftModel);
};

const updateShift = async (idShift, shiftModel) => {
	const request = `/Shifts/${idShift}`;
	return axiosInstance.put(request, shiftModel);
};

const deleteShiftById = async (shiftId, regionId) => {
	const request = `/Shifts/${shiftId}?regionId=${regionId}`;
	return axiosInstance.delete(request);
};

const deleteShiftPartById = async (shiftPartId) => {
	const request = `/Shifts/Parts/${shiftPartId}`;
	return axiosInstance.delete(request);
};

const getShiftRegionIds = async (idShift) => {
	const request = `/Shifts/${idShift}/regionIds`;
	return axiosInstance.get(request);
};

const ShiftService = {
	getShifts,
	getShiftsList,
	deleteShiftById,
	createShift,
	updateShift,
	deleteShiftPartById,
	getShiftRegionIds,
};

export default ShiftService;
