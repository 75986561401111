import { createSlice } from '@reduxjs/toolkit';
import {
	createCourier,
	retrieveCouriers,
	retrieveCourierApplies,
	updateCourier,
	getCourierById,
} from '../../actions/Couriers';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	couriers: [],
	courierApplies: [],
	totalCouriers: 0,
	totalCourierApplies: 0,
};

/**
 * Le slide ou reducers de la gestion des coursiers
 */
export const couriersSlice = createSlice({
	name: 'couriers',
	initialState,

	// Reducers
	reducers: {
		setLoading: (state) => {
			state.isLoading = true;
		},
		setSuccess: (state) => {
			state.isSuccess = true;
		},
		setCouriers: (state, { payload }) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.isError = false;
			state.couriers = payload.data;
		},
		setTotalCouriers: (state, { payload }) => {
			state.totalCouriers = payload.count;
		},
		setError: (state) => {
			state.isError = true;
		},
	},

	// Extra reducers
	extraReducers: {
		[createCourier.fulfilled]: (state, action) => {
			state.push(action.payload);
		},
		[retrieveCouriers.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveCourierApplies.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveCouriers.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.couriers = [...action.payload.data];
			state.totalCouriers = action.payload.count;
		},
		[retrieveCourierApplies.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.courierApplies = [...action.payload.data];
			state.totalCourierApplies = action.payload.count;
		},
		[retrieveCouriers.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
		[retrieveCourierApplies.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
		[updateCourier.fulfilled]: (state, action) => {
			const index = state.findIndex((courier) => courier.id === action.payload.id);
			state[index] = {
				...state[index],
				...action.payload,
			};
		},
		[getCourierById.fulfilled]: (state, action) => {
			return action.payload;
		},
	},
});

/**
 *  Export des actions du reducer
 */
export const { setLoading, setCouriers, setError, setSuccess, setTotalCouriers } =
	couriersSlice.actions;

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const couriersSelector = (state) => state.couriers;

/**
 * Export du reducer
 */
const { reducer } = couriersSlice;
export default reducer;
