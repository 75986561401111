import { createSlice } from '@reduxjs/toolkit';
import { retrieveOrder, retrieveOrders } from '../../../actions/Commercial/Orders';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	additionalItems: [],
	totalOrders: 0,
	order: {
		orderGuid: '',
		orderProductId: 1,
		orderProduct: {
			orderProductId: 0,
			kilometerMinimum: 0,
			kilometerMaximum: 0,
			price: 0,
			vehicleId: 0,
			vehicle: {
				vehicleId: 0,
				name: '',
				mode: '',
				languages: [],
				defaultLocalizedValue: '',
				localizedValue: '',
			},
			calculatedPrice: 0,
			calculatedFullPrice: {
				totalExVat: 0,
				vat: 0,
				total: 0,
			},
		},
		paymentStatus: null,
		calculatedPrice: 0,
		calculatedFullPrice: {
			totalExVat: 0,
			vat: 0,
			total: 0,
		},
		hasImmediateNeed: false,
		pickUpDate: '',
		pickUpTime: '',
		distanceInMeter: 0,
		durationInSecond: 0,
		paymentIntentId: '',
		paymentIntentSecretKey: '',
		weightId: 0,
		weight: {
			weightId: 0,
			name: '',
		},
		volumeId: 0,
		volume: {
			volumeId: 0,
			name: '',
		},
		pickUpOrderAdditionalItemId: 0,
		pickUpOrderAdditionalItem: {
			orderAdditionalItemId: 0,
			name: '',
			value: 0,
			price: 0,
			calculatedPrice: 0,
			calculatedFullPrice: {
				totalExVat: 0,
				vat: 0,
				total: 0,
			},
		},
		deliveryOrderAdditionalItemId: 0,
		deliveryOrderAdditionalItem: {
			orderAdditionalItemId: 0,
			name: '',
			value: 0,
			price: 0,
			calculatedPrice: 0,
			calculatedFullPrice: {
				totalExVat: 0,
				vat: 0,
				total: 0,
			},
		},
		pickUpOrderAddressId: 0,
		pickUpOrderAddress: {
			orderAddressId: 0,
			name: '',
			company: '',
			address1: '',
			zipCode: '',
			city: '',
			country: '',
			phoneNumber: '',
			complementaryInformation: '',
		},
		deliveryOrderAddresses: [],
	},
};

/**
 * Le slice ou reducers de la gestion des commandes
 */
export const ordersSlice = createSlice({
	name: 'orders',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrieveOrders.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveOrders.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.orders = [...action.payload.items];
			state.totalOrders = action.payload.totalCount;
		},
		[retrieveOrders.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
		[retrieveOrder.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveOrder.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.order = action.payload;
		},
		[retrieveOrder.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const ordersSelector = (state) => state.commercialOrders;

/**
 * Export du reducer
 */
const { reducer } = ordersSlice;
export default reducer;
