import { createSlice } from '@reduxjs/toolkit';
import { retrievePostalCodes } from '../../../actions/Commercial/PostalCodes';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	postalCodes: [],
	totalPostalCodes: 0,
};

/**
 * Le slice ou reducers de la gestion des postalCodes
 */
export const postalCodesSlice = createSlice({
	name: 'postalCodes',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrievePostalCodes.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrievePostalCodes.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.postalCodes = [...action.payload.items];
			state.totalPostalCodes = action.payload.totalCount;
		},
		[retrievePostalCodes.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const postalCodesSelector = (state) => state.commercialPostalCodes;

/**
 * Export du reducer
 */
const { reducer } = postalCodesSlice;
export default reducer;
