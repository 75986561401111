import React, { lazy } from 'react';
import Login from '../pages/presentation/auth/Login';
import { sitePaths } from '../site-paths';
import { AuthenticationContextProvider } from '../contexts/AuthenticationContext';
import IdleTimerUp from '../contexts/IdleTimer';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const MANAGEMENT = {
	COURIERS: {
		COURIERS_LIST: lazy(() => import('../pages/presentation/Couriers/CouriersList')),
		COURIER: lazy(() => import('../pages/presentation/Couriers/Courier')),
		COURIERSGEOLOC: lazy(() => import('../pages/presentation/Couriers/Geolocalization')),
		COURIERSPERFORMANCES: lazy(() => import('../pages/presentation/Couriers/Performances')),
	},
	APPLICANTS: {
		APPLICANTS_LIST: lazy(() =>
			import('../pages/presentation/CourierApplies/CourierAppliesList'),
		),
		APPLICANT: lazy(() => import('../pages/presentation/CourierApplies/CourierApply')),
	},
	ADMINISTRATORS: {
		ADMINISTRATORS_LIST: lazy(() =>
			import('../pages/presentation/Administrators/AdmistratorsList'),
		),
		ADMINISTRATOR: lazy(() => import('../pages/presentation/Administrators/Administrator')),
		ACCOUNTPROFILE: lazy(() =>
			import('../pages/common/Administrators/Administrator/AdministratorAccountProfile'),
		),
	},
	PROFILES: {
		PROFILES_LIST: lazy(() => import('../pages/presentation/Profiles/ProlfilesList')),
		PROFILE: lazy(() => import('../pages/presentation/Profiles/Profile')),
	},
};

const ORGANISATION = {
	SHIFTS: {
		SHIFTS_LIST: lazy(() => import('../pages/presentation/Shifts/ShiftsList')),
	},
	ABSENCES: {
		ABSENCES_LIST: lazy(() => import('../pages/presentation/Absences/AbsencesList')),
		ABSENCE: lazy(() => import('../pages/presentation/Absences/Absence')),
	},
};

const EXTRASMANAGEMENT = {
	EXTRAS: {
		MARKETPLACE: lazy(() => import('../pages/presentation/Extras/MarketPlaceExtras')),
		EXTRATAKEN: lazy(() => import('../pages/presentation/Extras/ExtrasTakenList')),
		EXTRACREATION: lazy(() => import('../pages/presentation/Extras/ExtraCreation')),
	},
};

const ADMINISTRATIVEHR = {
	PROCEDURES: {
		PROCEDURES_LIST: lazy(() => import('../pages/presentation/Procedures/ProceduresList')),
		PROCEDURE: lazy(() => import('../pages/presentation/Procedures/Procedure')),
	},
	WORKPERMITS: {
		WORKPERMITS_LIST: lazy(() => import('../pages/presentation/Absences/AbsencesList')),
	},
	AGENDAS: {
		AGENDAS_LIST: lazy(() => import('../pages/presentation/Agendas/AgendasList')),
	},
};

const COMMERCIAL = {
	ADDITIONAL_ITEMS: {
		ADDITIONAL_ITEMS_LIST: lazy(() =>
			import('../pages/commercial/AdditionalItems/AdditionalItemsList'),
		),
		ADDITIONAL_ITEMS_EDIT: lazy(() =>
			import('../pages/commercial/AdditionalItems/AdditionalItem'),
		),
	},
	ORDERS: {
		ORDERS_LIST: lazy(() => import('../pages/commercial/Orders/OrdersList')),
		ORDER: lazy(() => import('../pages/commercial/Orders/Order')),
	},
	POSTAL_CODES: {
		POSTAL_CODES_LIST: lazy(() => import('../pages/commercial/PostalCodes/PostalCodesList')),
		POSTAL_CODE: lazy(() => import('../pages/commercial/PostalCodes/PostalCode')),
	},
	PRODUCTS: {
		PRODUCTS_LIST: lazy(() => import('../pages/commercial/Products/ProductsList')),
		PRODUCTS_EDIT: lazy(() => import('../pages/commercial/Products/Product')),
	},
	VEHICLES: {
		VEHICLES_LIST: lazy(() => import('../pages/commercial/Vehicles/VehiclesList')),
	},
	WEIGHTS: {
		WEIGHTS_LIST: lazy(() => import('../pages/commercial/Weights/WeightsList')),
	},
	VOLUMES: {
		VOLUMES_LIST: lazy(() => import('../pages/commercial/Volumes/VolumesList')),
	},
};

const presentation = [
	/**
	 * Landing
	 */

	{
		path: sitePaths.paths.dashboard,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<LANDING.DASHBOARD />
			</AuthenticationContextProvider>
		),
		exact: true,
	},

	/**
	 * Auth Page
	 */
	{
		path: sitePaths.paths.page404,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: sitePaths.paths.login,
		element: <Login />,
		exact: true,
	},

	/**
	 * Managements
	 */

	/**
	 * Managements > Couriers
	 */
	{
		path: sitePaths.paths.couriersList,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<MANAGEMENT.COURIERS.COURIERS_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageCouriers.View',
	},
	{
		path: sitePaths.paths.couriersGeolocs,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<MANAGEMENT.COURIERS.COURIERSGEOLOC />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageGeolocs.Read',
	},
	{
		path: sitePaths.paths.couriersPerformances,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<MANAGEMENT.COURIERS.COURIERSPERFORMANCES />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManagePerformances.Read',
	},
	{
		path: sitePaths.paths.courier,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<MANAGEMENT.COURIERS.COURIER />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageCourier.View',
	},
	/**
	 * Managements > Applicants
	 */
	{
		path: sitePaths.paths.applicantsList,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<MANAGEMENT.APPLICANTS.APPLICANTS_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageApplicants.View',
	},
	{
		path: sitePaths.paths.applicant,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<MANAGEMENT.APPLICANTS.APPLICANT />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageApplicant.View',
	},

	/**
	 * Managements > Administrators
	 */
	{
		path: sitePaths.paths.administratorsList,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<MANAGEMENT.ADMINISTRATORS.ADMINISTRATORS_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageAdmins.View',
	},
	{
		path: sitePaths.paths.administrator,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<MANAGEMENT.ADMINISTRATORS.ADMINISTRATOR />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageAdmin.Edit',
	},
	{
		path: sitePaths.paths.profileAdministrator,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<MANAGEMENT.ADMINISTRATORS.ACCOUNTPROFILE />
			</AuthenticationContextProvider>
		),
		exact: true,
	},
	/**
	 * Managements > Profiles
	 */
	{
		path: sitePaths.paths.profilesList,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<MANAGEMENT.PROFILES.PROFILES_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageRoles.View',
	},
	{
		path: sitePaths.paths.profile,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<MANAGEMENT.PROFILES.PROFILE />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageRole.Edit',
	},
	/**
	 * END - Managements
	 */

	/**
	 * Organisations
	 */

	/**
	 * Organisations > Shifts
	 */
	{
		path: sitePaths.paths.shiftsList,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<ORGANISATION.SHIFTS.SHIFTS_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
	},
	/**
	 * Organisations > Absences
	 */
	{
		path: sitePaths.paths.absencesList,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<ORGANISATION.ABSENCES.ABSENCES_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageAbsences.View',
	},
	{
		path: sitePaths.paths.absence,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<ORGANISATION.ABSENCES.ABSENCE />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageAbsence.View',
	},
	/**
	 * Extras
	 */
	{
		path: sitePaths.paths.extrasmarketPlace,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<EXTRASMANAGEMENT.EXTRAS.MARKETPLACE />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageExtras.View',
	},
	{
		path: sitePaths.paths.extrasTaken,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<EXTRASMANAGEMENT.EXTRAS.EXTRATAKEN />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageExtras.Affected.View',
	},
	{
		path: sitePaths.paths.extrasCreation,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<EXTRASMANAGEMENT.EXTRAS.EXTRACREATION />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageExtra.Create',
	},
	/**
	 * END - Extras
	 */

	/**
	 * Administrative-HR
	 */

	/**
	 * Administrative-HR > Procedures
	 */
	{
		path: sitePaths.paths.proceduresList,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<ADMINISTRATIVEHR.PROCEDURES.PROCEDURES_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageProcedures.View',
	},
	{
		path: sitePaths.paths.procedure,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<ADMINISTRATIVEHR.PROCEDURES.PROCEDURE />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageProcedures.View',
	},

	/**
	 * Administrative-HR > Work permits
	 */
	{
		path: sitePaths.paths.workPermits,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<ADMINISTRATIVEHR.WORKPERMITS.WORKPERMITS_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageWorkPermits.View',
	},
	/**
	 * Administrative-HR > Agendas
	 */
	{
		path: sitePaths.paths.agendas,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<ADMINISTRATIVEHR.AGENDAS.AGENDAS_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.ManageAgendas.View',
	},

	/**
	 * Commercial > AdditionalItems
	 */
	{
		path: sitePaths.paths.commercialAdditionalItemsList,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<COMMERCIAL.ADDITIONAL_ITEMS.ADDITIONAL_ITEMS_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.Commercial.ManageAdditionalItems.View',
	},

	/**
	 * Commercial > AdditionalItems > Edit
	 */
	{
		path: sitePaths.paths.commercialAdditionalItemEdit,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<COMMERCIAL.ADDITIONAL_ITEMS.ADDITIONAL_ITEMS_EDIT />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.Commercial.ManageAdditionalItems.Edit',
	},

	/**
	 * Commercial > Orders
	 */
	{
		path: sitePaths.paths.commercialOrdersList,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<COMMERCIAL.ORDERS.ORDERS_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.Commercial.ManageOrders.View',
	},
	{
		path: sitePaths.paths.commercialOrder,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<COMMERCIAL.ORDERS.ORDER />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.Commercial.ManageOrders.Edit',
	},

	/**
	 * Commercial > PostalCodes
	 */
	{
		path: sitePaths.paths.commercialPostalCodesList,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<COMMERCIAL.POSTAL_CODES.POSTAL_CODES_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.Commercial.ManagePostalCodes.View',
	},

	/**
	 * Commercial > PostalCodes > Détails
	 */
	{
		path: sitePaths.paths.commercialPostalCodesEdit,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<COMMERCIAL.POSTAL_CODES.POSTAL_CODE />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.Commercial.ManagePostalCodes.Edit',
	},

	/**
	 * Commercial > Products
	 */
	{
		path: sitePaths.paths.commercialProductsList,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<COMMERCIAL.PRODUCTS.PRODUCTS_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.Commercial.ManageProducts.View',
	},

	/**
	 * Commercial > Products > Edit
	 */
	{
		path: sitePaths.paths.commercialProductsEdit,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<COMMERCIAL.PRODUCTS.PRODUCTS_EDIT />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.Commercial.ManageProducts.Edit',
	},

	/**
	 * Commercial > Vehicles
	 */
	{
		path: sitePaths.paths.commercialVehiclesList,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<COMMERCIAL.VEHICLES.VEHICLES_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.Commercial.ManageVehicles.View',
	},

	/**
	 * Commercial > Weights
	 */
	{
		path: sitePaths.paths.commercialWeightsList,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<COMMERCIAL.WEIGHTS.WEIGHTS_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.Commercial.ManageWeights.View',
	},

	/**
	 * Commercial > Volumes
	 */
	{
		path: sitePaths.paths.commercialVolumesList,
		element: (
			<AuthenticationContextProvider>
				<IdleTimerUp />
				<COMMERCIAL.VOLUMES.VOLUMES_LIST />
			</AuthenticationContextProvider>
		),
		exact: true,
		right: 'Permissions.Commercial.ManageVolumes.View',
	},
];
const contents = [...presentation];

export default contents;
