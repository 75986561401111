import axios from 'axios';
import {
	responseSuccessHandler,
	responseErrorHandler,
	requestSuccessHandler,
	requestErrorHandler,
} from './AxiosConfig';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASECOMMERCIALURL,
});

axiosInstance.interceptors.request.use(requestSuccessHandler, requestErrorHandler);

axiosInstance.interceptors.response.use(responseSuccessHandler, responseErrorHandler);

export default axiosInstance;
