import axiosInstance from '../../configurations/AxiosConfig.Commercial';

const getWeights = async (filtersProcessor) => {
	const request = `/v1/Weights`;
	return axiosInstance.get(request, { params: filtersProcessor });
};

const getWeightById = async (id) => {
	const request = `/v1/Weights/${id}`;
	return axiosInstance.get(request);
};

const WeightService = {
	getWeights,
	getWeightById,
};

export default WeightService;
