import { createAsyncThunk } from '@reduxjs/toolkit';
import VolumeService from '../../../../services/Commercial/VolumeService';
import { RETRIEVE_VOLUME, RETRIEVE_VOLUMES } from '../../../constants/Commercial/Volumes';

export const retrieveVolumes = createAsyncThunk(RETRIEVE_VOLUMES, async (filtersProcessor) => {
	const response = await VolumeService.getVolumes(filtersProcessor);
	return response.data;
});

export const retrieveVolume = createAsyncThunk(RETRIEVE_VOLUME, async (id) => {
	const response = await VolumeService.getVolumeById(id);
	return response.data;
});
