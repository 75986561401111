import { createSlice } from '@reduxjs/toolkit';
import { retrieveVolume, retrieveVolumes } from '../../../actions/Commercial/Volumes';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	volumes: [],
	totalVolumes: 0,
	volume: {
		volumeId: 0,
		name: '',
		languages: [],
	},
};

/**
 * Le slice ou reducers de la gestion des volumes
 */
export const volumesSlice = createSlice({
	name: 'volumes',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrieveVolumes.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveVolumes.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.volumes = [...action.payload.items];
			state.totalVolumes = action.payload.totalCount;
		},
		[retrieveVolumes.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
		[retrieveVolume.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveVolume.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.volume = action.payload;
		},
		[retrieveVolume.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const volumesSelector = (state) => state.commercialVolumes;

/**
 * Export du reducer
 */
const { reducer } = volumesSlice;
export default reducer;
