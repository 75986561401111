import { sitePaths } from './site-paths';

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: sitePaths.paths.dashboard,
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const ManagementMenu = {
	managements: {
		id: 'Managements',
		text: 'Managements',
		icon: 'Extension',
	},
	couriersManagement: {
		id: 'couriersMana',
		text: 'Couriers Management',
		path: '/1',
		icon: 'RunCircle',
		right: 'Permissions.Menu.ManageCouriers',
		subMenu: {
			couriersList: {
				id: 'couriersList',
				text: 'Couriers List',
				path: sitePaths.paths.couriersList,
				icon: 'RecentActors',
				right: 'Permissions.ManageCouriers.View',
			},
			couriersGeolocs: {
				id: 'couriersGeolocs',
				text: 'Geolocalization',
				path: sitePaths.paths.couriersGeolocs,
				icon: 'MyLocation',
				right: 'Permissions.Menu.ManageGeolocs',
			},
			couriersPerformances: {
				id: 'couriersPerformances',
				text: 'Performances',
				path: sitePaths.paths.couriersPerformances,
				icon: 'QueryStats',
				right: 'Permissions.Menu.ManagePerformances',
				hide: true,
			},
		},
	},
	applicantsManagement: {
		id: 'customersMana',
		text: 'Applicants Management',
		path: '/3',
		icon: 'Contacts',
		right: 'Permissions.Menu.ManageApplicants',
		subMenu: {
			applicantsList: {
				id: 'customersList',
				text: 'Applicants List',
				path: sitePaths.paths.applicantsList,
				icon: 'ArtTrack',
				right: 'Permissions.ManageApplicants.View',
			},
		},
	},
};

export const ApplicationMenu = {
	application: {
		id: 'application',
		text: 'Apps',
	},
	timeManagement: {
		id: 'timeManagement',
		text: 'Time Management',
		path: '/6',
		icon: 'Apps',
		right: 'Permissions.Menu.ManageTimes',
		subMenu: {
			planning: {
				id: 'planning',
				text: 'Planning',
				path: '/7',
				icon: 'EditCalendar',
				hide: true,
			},
			shift: {
				id: 'shift',
				text: 'Shifts',
				path: sitePaths.paths.shiftsList,
				icon: 'LocalShipping',
				right: 'Permissions.Menu.ManageShifts',
			},
			absenceMana: {
				id: 'absenceMana',
				text: 'Absence Management',
				path: sitePaths.paths.absencesList,
				icon: 'PersonSearch',
				right: 'Permissions.Menu.ManageAbsences',
			},
		},
	},
	extrasManagement: {
		id: 'extrasManagement',
		text: 'Extras Management',
		path: '/6',
		icon: 'Event',
		right: 'Permissions.Menu.ManageExtras',
		subMenu: {
			extraCreation: {
				id: 'extraCreation',
				text: 'Manage Extra',
				path: sitePaths.paths.extrasCreation,
				icon: 'CreditScore',
				right: 'Permissions.ManageExtra.Create',
			},
			marketPlace: {
				id: 'marketPlace',
				text: 'MarketPlace',
				path: sitePaths.paths.extrasmarketPlace,
				icon: 'JournalBookmarkFill',
				right: 'Permissions.ManageExtras.View',
			},
			extraTaken: {
				id: 'extraTaken',
				text: 'Visualization',
				path: sitePaths.paths.extrasTaken,
				icon: 'SmartToy',
				right: 'Permissions.ManageExtras.Affected.View',
			},
		},
	},
	administrativeHRManagement: {
		id: 'adminManagementHR',
		text: 'Administrative / HR',
		path: '/10',
		icon: 'ChromeReaderMode',
		hide: true,
		right: 'Permissions.Menu.ManageAdminHR',
		subMenu: {
			procedures: {
				id: 'procedures',
				text: 'Procedures',
				path: sitePaths.paths.proceduresList,
				icon: 'SelfImprovement',
				hide: false,
				right: 'Permissions.Menu.ManageProcedures',
			},
			workPermits: {
				id: 'workPermits',
				text: 'Deadlines work permits',
				path: sitePaths.paths.workPermits,
				icon: 'BatchPrediction',
				hide: true,
				right: 'Permissions.Menu.ManageWorkPermits',
			},
			agendas: {
				id: 'agendas',
				text: 'Agendas',
				path: sitePaths.paths.agendas,
				icon: 'BatchPrediction',
				hide: false,
				right: 'Permissions.Menu.ManageAgendas',
			},
		},
	},
	maps: {
		id: 'maps',
		text: 'Maps',
		path: '/5',
		icon: 'PinMap',
		hide: true,
		subMenu: null,
	},
};

export const AdministrationMenu = {
	adminManagement: {
		id: 'adminManagement',
		text: 'Administration',
		path: '/10',
		icon: 'AdminPanelSettings',
		hide: false,
		right: 'Permissions.Menu.ManageAdmins',
		subMenu: {
			rights: {
				id: 'rights',
				text: 'Rights',
				path: '/11',
				icon: 'VerifiedUser',
				hide: true,
			},
			roles: {
				id: 'roles',
				text: 'Roles',
				path: sitePaths.paths.profilesList,
				icon: 'BrightnessAuto',
				hide: false,
				right: 'Permissions.ManageRoles.View',
			},
			users: {
				id: 'users',
				text: 'Users',
				path: sitePaths.paths.administratorsList,
				icon: 'PersonPin',
				hide: false,
				right: 'Permissions.ManageAdmins.View',
			},
		},
	},
};

export const CommercialMenu = {
	application: {
		id: 'commercial',
		text: 'Commercial',
	},

	commercialOrdersManagement: {
		id: 'commercialOrdersManagement',
		text: 'Orders Management',
		path: '/1',
		icon: 'AccountBalance',
		right: 'Permissions.Menu.Commercial.ManageOrders',
		subMenu: {
			commercialOrdersList: {
				id: 'commercialOrdersList',
				text: 'Orders List',
				path: sitePaths.paths.commercialOrdersList,
				icon: 'List',
				right: 'Permissions.Commercial.ManageOrders.View',
			},
		},
	},

	commercialPostalCodesManagement: {
		id: 'commercialPostalCodesManagement',
		text: 'PostalCodes Management',
		path: '/1',
		icon: 'LocalPostOffice',
		right: 'Permissions.Menu.Commercial.ManagePostalCodes',
		subMenu: {
			commercialPostalCodesList: {
				id: 'commercialPostalCodesList',
				text: 'Postal Codes List',
				path: sitePaths.paths.commercialPostalCodesList,
				icon: 'List',
				right: 'Permissions.Commercial.ManagePostalCodes.View',
			},
		},
	},

	commercialProductsManagement: {
		id: 'commercialProductsManagement',
		text: 'Products Management',
		path: '/1',
		icon: 'Business',
		right: 'Permissions.Menu.Commercial.ManageProducts',
		subMenu: {
			commercialProductsList: {
				id: 'commercialProductsList',
				text: 'Products List',
				path: sitePaths.paths.commercialProductsList,
				icon: 'List',
				right: 'Permissions.Commercial.ManageProducts.View',
			},
		},
	},
	commercialAdditionalItemsManagement: {
		id: 'commercialAdditionalItemsManagement',
		text: 'AdditionalItems Management',
		path: '/1',
		icon: 'AddBusiness',
		right: 'Permissions.Menu.Commercial.ManageAdditionalItems',
		subMenu: {
			commercialAdditionalItemsList: {
				id: 'commercialAdditionalItemsList',
				text: 'Additional Items List',
				path: sitePaths.paths.commercialAdditionalItemsList,
				icon: 'List',
				right: 'Permissions.Commercial.ManageAdditionalItems.View',
			},
		},
	},
	commercialVehiclesManagement: {
		id: 'commercialVehiclesManagement',
		text: 'Vehicles Management',
		path: '/1',
		icon: 'DirectionsCar',
		right: 'Permissions.Menu.Commercial.ManageVehicles',
		subMenu: {
			commercialVehiclesList: {
				id: 'commercialVehiclesList',
				text: 'Vehicles List',
				path: sitePaths.paths.commercialVehiclesList,
				icon: 'List',
				right: 'Permissions.Commercial.ManageVehicles.View',
			},
		},
	},
	commercialWeightsManagement: {
		id: 'commercialWeightsManagement',
		text: 'Weights Management',
		path: '/1',
		icon: 'Speed',
		right: 'Permissions.Menu.Commercial.ManageWeights',
		subMenu: {
			commercialWeightsList: {
				id: 'commercialWeightsList',
				text: 'Weights List',
				path: sitePaths.paths.commercialWeightsList,
				icon: 'List',
				right: 'Permissions.Commercial.ManageWeights.View',
			},
		},
	},
	commercialVolumesManagement: {
		id: 'commercialVolumesManagement',
		text: 'Volumes Management',
		path: '/1',
		icon: 'SettingsOverscan',
		right: 'Permissions.Menu.Commercial.ManageVolumes',
		subMenu: {
			commercialVolumesList: {
				id: 'commercialVolumesList',
				text: 'Volumes List',
				path: sitePaths.paths.commercialVolumesList,
				icon: 'List',
				right: 'Permissions.Commercial.ManageVolumes.View',
			},
		},
	},
};
