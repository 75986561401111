import { Ability, AbilityBuilder } from '@casl/ability';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import LocalStorageService from '../services/LocalStorageService';

const decodeTokenToGetRight = (accessToken) => {
	const decoded = jwt_decode(accessToken);
	return decoded.role;
};

const refresh = () => {
	const item = new Ability();
	const userConnected = LocalStorageService.getItem('auth_user');
	if (userConnected) {
		const rights = decodeTokenToGetRight(userConnected?.accessToken);

		if (rights) {
			setAbilities(item, rights);
		}
	}

	return item;
};

const setAbilities = (item, rights) => {
	const { can, rules } = new AbilityBuilder(Ability);

	rights?.forEach((right) => {
		can('manage', `${right}`);
	});
	item.update(rules);
};

const ability = refresh();

function updateAbility(rights) {
	setAbilities(ability, rights);
}

export function updateAbilityWithToken(accessToken) {
	updateAbility(decodeTokenToGetRight(accessToken));
}

export default ability;
