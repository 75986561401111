import { createSlice } from '@reduxjs/toolkit';
import { retrieveWeight, retrieveWeights } from '../../../actions/Commercial/Weights';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	weights: [],
	totalWeights: 0,
	weight: {
		weightId: 0,
		name: '',
		allowedVolumes: [],
		languages: [],
	},
};

/**
 * Le slice ou reducers de la gestion des poids
 */
export const weightsSlice = createSlice({
	name: 'weights',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrieveWeights.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveWeights.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.weights = [...action.payload.items];
			state.totalWeights = action.payload.totalCount;
		},
		[retrieveWeights.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
		[retrieveWeight.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveWeight.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.weight = action.payload;
		},
		[retrieveWeight.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const weightsSelector = (state) => state.commercialWeights;

/**
 * Export du reducer
 */
const { reducer } = weightsSlice;
export default reducer;
