import { createAsyncThunk } from '@reduxjs/toolkit';
import { RETRIEVE_PROCEDURES, RETRIEVE_PROCEDURE } from '../../constants/Procedures';
import ProcedureService from '../../../services/ProcedureService';

export const retrieveProcedures = createAsyncThunk(
	RETRIEVE_PROCEDURES,
	async (filtersProcessor) => {
		const response = await ProcedureService.getProcedures(filtersProcessor);
		return response.data;
	},
);

export const getProcedureById = createAsyncThunk(RETRIEVE_PROCEDURE, async (idProcedure) => {
	return idProcedure;
});
