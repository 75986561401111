import { createSlice } from '@reduxjs/toolkit';
import { retrieveProduct } from '../../../actions/Commercial/Products';

/**
 *  Etat initial
 */
export const productInitialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	product: {
		productId: 0,
		kilometerMinimum: 0,
		kilometerMaximum: 0,
		price: 0,
		vehicleId: 0,
	},
};

/**
 * Le slice ou reducers de la gestion d'un produit
 */
export const productSlice = createSlice({
	name: 'product',
	initialState: productInitialState,
	extraReducers: {
		[retrieveProduct.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveProduct.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.product = action.payload;
		},
		[retrieveProduct.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const productSelector = (state) => state.commercialProduct;

/**
 * Export du reducer
 */
const { reducer } = productSlice;
export default reducer;
