import axiosInstance from '../../configurations/AxiosConfig.Commercial';

const getPostalCodes = async (filtersProcessor) => {
	const request = `/v1/PostalCodes`;
	return axiosInstance.get(request, { params: filtersProcessor });
};
const getPostalCodeById = async (id) => {
	const request = `/v1/PostalCodes/${id}`;
	return axiosInstance.get(request);
};
const putPostalCode = async (id, values, isConfirmed) => {
	const request = `/v1/PostalCodes/${id}`;
	return axiosInstance.put(request, values, { params: { isConfirmed } });
};
const createPostalCode = async (values, isConfirmed) => {
	const request = `/v1/PostalCodes`;
	return axiosInstance.post(request, values, { params: { isConfirmed } });
};

const PostalCodeService = {
	getPostalCodes,
	getPostalCodeById,
	putPostalCode,
	createPostalCode,
};

export default PostalCodeService;
