import { createSlice } from '@reduxjs/toolkit';
import {
	retrieveProfiles,
	retrieveProfilesList,
	retrieveOrganizationsList,
} from '../../actions/Profiles';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	profiles: [],
	organizations: [],
	totalProfiles: 0,
};

/**
 * Le slide ou reducers de la gestion des profiles
 */
export const profilesSlice = createSlice({
	name: 'profiles',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrieveProfiles.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveProfiles.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.profiles = [...action.payload.data];
			state.totalProfiles = action.payload.count;
		},
		[retrieveProfiles.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
		[retrieveProfilesList.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.profiles = [...action.payload];
		},
		[retrieveOrganizationsList.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.organizations = [...action.payload];
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const profilesSelector = (state) => state.profiles;

/**
 * Export du reducer
 */
const { reducer } = profilesSlice;
export default reducer;
