import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import { sitePaths } from '../../../site-paths';
import Breadcrumb from '../../../components/bootstrap/Breadcrumb';

const CommonPageHeader = ({ PageTitle }) => {
	const { t } = useTranslation('menu');
	return (
		<Header>
			<HeaderLeft>
				<Breadcrumb
					list={[
						{ title: t('Dashboard'), to: `${sitePaths.paths.dashboard}` },
						{ title: t(`${PageTitle}`), to: `${sitePaths.paths.dashboard}` },
					]}
				/>
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

CommonPageHeader.propTypes = {
	PageTitle: PropTypes.string.isRequired,
};

export default CommonPageHeader;
