import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocalStorage } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { sitePaths } from '../site-paths';
import { checkValidationToken, refreshUserToken } from '../services/AuthenticationService';
import LocalStorageService from '../services/LocalStorageService';

export const AuthenticationContextProvider = ({ children }) => {
	const navigate = useNavigate();

	const [value] = useLocalStorage('auth_user');

	useEffect(() => {
		let userIsAuthenticated = false;

		if (value) {
			const { accessToken, refreshToken } = value;
			if (accessToken) {
				checkValidationToken(accessToken)
					.then((response) => {
						userIsAuthenticated = response.data;

						if (!userIsAuthenticated) {
							refreshUserToken(refreshToken)
								.then((responseToken) => {
									LocalStorageService.setItem('auth_user', responseToken.data);
								})
								.catch(() => {
									LocalStorageService.removeAll();
									navigate(`../${sitePaths.paths.login}`);
								});
						}
					})
					.catch(() => {
						LocalStorageService.removeAll();
						navigate(`../${sitePaths.paths.login}`);
					});
			}
		} else {
			navigate(`../${sitePaths.paths.login}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, value, value?.accessToken]);

	return children;
};

AuthenticationContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthenticationContextProvider;
