import { createSlice } from '@reduxjs/toolkit';
import { retrieveProcedures } from '../../actions/Procedures';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	procedures: [],
	totalProcedures: 0,
};

/**
 * Le slide ou reducers de la gestion des démarches des coursiers
 */
export const proceduresSlice = createSlice({
	name: 'procedures',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrieveProcedures.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveProcedures.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.procedures = [...action.payload.data];
			state.totalProcedures = action.payload.count;
		},
		[retrieveProcedures.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const proceduresSelector = (state) => state.procedures;

/**
 * Export du reducer
 */
const { reducer } = proceduresSlice;
export default reducer;
