import axiosInstance from '../configurations/AxiosConfig';

const getAdministrators = async (filtersProcessor) => {
	const request = `/Administrators/Retrieve`;
	return axiosInstance.post(request, filtersProcessor);
};

const getAdministratorById = async (administratorId) => {
	const request = `/Administrators/${administratorId}`;
	return axiosInstance.get(request);
};

const deleteAdministratorById = async (administratorId) => {
	const request = `/Administrators/${administratorId}`;
	return axiosInstance.delete(request);
};

const createAdministrator = async (administratorModel) => {
	const request = `/Administrators`;
	return axiosInstance.post(request, administratorModel);
};

const updateAdministrator = async (administratorId, administratorModel) => {
	const request = `/Administrators/${administratorId}`;
	return axiosInstance.put(request, administratorModel);
};

const updateAdministratorPassword = async (administratorId, passwordrModel) => {
	const request = `/Administrators/${administratorId}/ChangePassword`;
	return axiosInstance.put(request, passwordrModel);
};

const AdministratorService = {
	getAdministrators,
	getAdministratorById,
	deleteAdministratorById,
	createAdministrator,
	updateAdministrator,
	updateAdministratorPassword,
};

export default AdministratorService;
