import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from 'react-use';
import USERS from '../../common/data/userDummyData';
import { sitePaths } from '../../site-paths';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import { logOut } from '../../services/AuthenticationService';

export const UserAvatar = ({ srcSet, src }) => {
	return (
		<div className='user-avatar'>
			<img srcSet={srcSet} src={src} alt='Avatar' width={128} height={128} />
		</div>
	);
};
UserAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
	srcSet: null,
};

const User = () => {
	const navigate = useNavigate();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [userConnectedJWT] = useLocalStorage('auth_user');

	/**
	 *  Attribut de traduction
	 * @type {*}
	 * */
	const { t } = useTranslation('translation');

	/**
	 * Attribut de l'utilisateur connecté
	 *  @type {*}
	 *  */
	const [userConnected] = useState(
		userConnectedJWT !== undefined ? jwt_decode(userConnectedJWT?.accessToken) : null,
	);

	const handleOnClick = async () => {
		logOut();
		navigate(`../${sitePaths.paths.login}`);
	};

	return (
		<Dropdown>
			<DropdownToggle hasIcon={false}>
				<div className='user'>
					<UserAvatar srcSet={USERS.JOHN.srcSet} src={USERS.JOHN.src} />
					<div className='user-info'>
						<div className='user-name'>{userConnected?.DisplayName}</div>
						<div className='user-sub-title'>{userConnected?.Profile}</div>
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem>
					<Button
						icon='AccountBox'
						onClick={() =>
							navigate(
								`../${sitePaths.paths.administratorsList}/profile/${Number(
									userConnected?.Id,
								)}`,
							)
						}>
						Profile
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
				<DropdownItem isDivider />
				<DropdownItem>
					<Button icon='Logout' onClick={() => handleOnClick()}>
						{t('Logout')}
					</Button>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

export default User;
