import { createAsyncThunk } from '@reduxjs/toolkit';
import { RETRIEVE_AGENDAS } from '../../constants/Agendas';
import AgendaService from '../../../services/AgendaService';

export const retrieveAgendas = createAsyncThunk(RETRIEVE_AGENDAS, async (filtersProcessor) => {
	const response = await AgendaService.getAgendas(filtersProcessor);
	return response.data;
});

export default retrieveAgendas;
