import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { FLUSH_POSTAL_CODE, RETRIEVE_POSTAL_CODE, RETRIEVE_POSTAL_CODES } from '../../../constants/Commercial/PostalCodes';
import PostalCodeService from '../../../../services/Commercial/PostalCodeService';

// eslint-disable-next-line import/prefer-default-export
export const retrievePostalCodes = createAsyncThunk(
	RETRIEVE_POSTAL_CODES,
	async (filtersProcessor) => {
		const response = await PostalCodeService.getPostalCodes(filtersProcessor);
		return response.data;
	},
);

export const retrievePostalCode = createAsyncThunk(
	RETRIEVE_POSTAL_CODE, async (id)=>{
		const response = await PostalCodeService.getPostalCodeById(id);
		return response.data;
	}	
);

export const flushPostalCode = createAction(FLUSH_POSTAL_CODE);
