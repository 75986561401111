import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	RETRIEVE_NATIONALITIES,
	RETRIEVE_CIVILSTATUS,
	RETRIEVE_DRIVINGLICENSES,
	RETRIEVE_ACTIVITIES,
	RETRIEVE_OTHERSACTIVITIES,
	RETRIEVE_CONTRACTSTATUS,
	RETRIEVE_CONTRACTCLIENT,
	RETRIEVE_CONTRACTTYPE,
	RETRIEVE_CONTRACTREASONEND,
	RETRIEVE_SHIFTTYPE,
	RETRIEVE_RIGHTTYPE,
	RETRIEVE_RIGHT,
	RETRIEVE_ABSENCETYPE,
	RETRIEVE_DOCUMENTTYPE,
	RETRIEVE_DOCUMENTSTATUS,
	RETRIEVE_ABSENCESTATUS,
	RETRIEVE_COURIERAPPLYSTATUS,
	RETRIEVE_EXTRATYPE,
	RETRIEVE_COLORS,
	RETRIEVE_PROCEDURES_NAMES,
	RETRIEVE_TEAMLEADERS,
	RETRIEVE_SITUATIONS,
	RETRIEVE_STATUTINVITATION,
	RETRIEVE_TYPEINVITATION,
	RETRIEVE_SWISSCANTON,
	RETRIEVE_PROCEDURES_STATUS,
	RETRIEVE_TEAMLEADER_BY_ID,
	RETRIEVE_TEAM_LEADER_INFO_TYPES,
	RETRIEVE_CONTRACTMOTIVEEND,
	RETRIEVE_VEHICLETYPE,
	RETRIEVE_VEHICLECOLOR,
	RETRIEVE_SPOUSEACTIVITIES,
	RETRIEVE_TOWNS,
	RETRIEVE_DEPARTMENTS,
	RETRIEVE_WORKPERMITAPPLIEDS,
	RETRIEVE_TAXSCALES,
	RETRIEVE_TAXCHILDCOUNT,
	RETRIEVE_TAXCULTTAXES,
	RETRIEVE_TAXCODES_BY_CANTON,
} from '../../constants/Commons';
import CommonService from '../../../services/CommonService';
import ColorService from '../../../services/ColorService';
import ProcedureService from '../../../services/ProcedureService';
import TaxService from '../../../services/TaxService';

export const retrieveCivilStatus = createAsyncThunk(RETRIEVE_CIVILSTATUS, async () => {
	const response = await CommonService.getCivilStatus();
	return response.data;
});

export const retrieveNationalities = createAsyncThunk(RETRIEVE_NATIONALITIES, async () => {
	const response = await CommonService.getNationalities();
	return response.data;
});

export const retrieveDrivingLicenses = createAsyncThunk(RETRIEVE_DRIVINGLICENSES, async () => {
	const response = await CommonService.getDrivingLicenses();
	return response.data;
});

export const retrievePrincipalActivities = createAsyncThunk(RETRIEVE_ACTIVITIES, async () => {
	const response = await CommonService.getPrincipalActivities();
	return response.data;
});

export const retrieveOthersActivities = createAsyncThunk(RETRIEVE_OTHERSACTIVITIES, async () => {
	const response = await CommonService.getPrincipalActivities();
	return response.data;
});

export const retrieveContractStatus = createAsyncThunk(RETRIEVE_CONTRACTSTATUS, async () => {
	const response = await CommonService.getContractStatus();
	return response.data;
});

export const retrieveContractClient = createAsyncThunk(RETRIEVE_CONTRACTCLIENT, async () => {
	const response = await CommonService.getContractClients();
	return response.data;
});

export const retrieveSituations = createAsyncThunk(RETRIEVE_SITUATIONS, async () => {
	const response = await CommonService.getSituations();
	return response.data;
});

export const retrieveShiftType = createAsyncThunk(RETRIEVE_SHIFTTYPE, async () => {
	const response = await CommonService.getShiftTypes();
	return response.data;
});

export const retrieveContractType = createAsyncThunk(RETRIEVE_CONTRACTTYPE, async () => {
	const response = await CommonService.getContractTypes();
	return response.data;
});

export const retrieveContractReasonEnd = createAsyncThunk(RETRIEVE_CONTRACTREASONEND, async () => {
	const response = await CommonService.getContractReasonEnd();
	return response.data;
});

export const retrieveRightType = createAsyncThunk(RETRIEVE_RIGHTTYPE, async () => {
	const response = await CommonService.getRightTypes();
	return response.data;
});

export const retrieveRight = createAsyncThunk(RETRIEVE_RIGHT, async ({ rightTypeId }) => {
	const response = await CommonService.getRightByRightTypeId(rightTypeId);
	return response.data;
});

export const retrieveAbsenceType = createAsyncThunk(RETRIEVE_ABSENCETYPE, async () => {
	const response = await CommonService.getAbsenceTypes();
	return response.data;
});

export const retrieveDocumentType = createAsyncThunk(RETRIEVE_DOCUMENTTYPE, async () => {
	const response = await CommonService.getDocumentTypes();
	return response.data;
});

export const retrieveDocumentStatus = createAsyncThunk(RETRIEVE_DOCUMENTSTATUS, async () => {
	const response = await CommonService.getDocumentStatus();
	return response.data;
});

export const retrieveAbsenceStatus = createAsyncThunk(RETRIEVE_ABSENCESTATUS, async () => {
	const response = await CommonService.getAbsenceStatus();
	return response.data;
});

export const retrieveCourierApplyStatus = createAsyncThunk(
	RETRIEVE_COURIERAPPLYSTATUS,
	async () => {
		const response = await CommonService.getCourierApplyStatus();
		return response.data;
	},
);

export const retrieveExtraTypes = createAsyncThunk(RETRIEVE_EXTRATYPE, async () => {
	const response = await CommonService.getExtraTypes();
	return response.data;
});

export const retrieveColors = createAsyncThunk(RETRIEVE_COLORS, async () => {
	const response = await ColorService.getColorsOptions();
	return response.data;
});

export const retrieveProceduresNames = createAsyncThunk(
	RETRIEVE_PROCEDURES_NAMES,
	async ({ languageCode }) => {
		const response = await ProcedureService.getOptionsProcedures(languageCode);
		return response.data;
	},
);

export const retrieveTeamLeaders = createAsyncThunk(RETRIEVE_TEAMLEADERS, async ({ regionId }) => {
	const response = await CommonService.getTeamLeaders(regionId);
	return response.data;
});

export const retrieveStatutInvitations = createAsyncThunk(RETRIEVE_STATUTINVITATION, async () => {
	const response = await CommonService.getStatutInvitations();
	return response.data;
});

export const retrieveTypeInvitations = createAsyncThunk(RETRIEVE_TYPEINVITATION, async () => {
	const response = await CommonService.getTypeInvitations();
	return response.data;
});

export const retrieveSwissCantons = createAsyncThunk(RETRIEVE_SWISSCANTON, async () => {
	const response = await CommonService.getSwissCantons();
	return response.data;
});

export const retrieveStatutProcedures = createAsyncThunk(RETRIEVE_PROCEDURES_STATUS, async () => {
	const response = await CommonService.getStatutProcedures();
	return response.data;
});

export const retrieveTeamLeaderById = createAsyncThunk(
	RETRIEVE_TEAMLEADER_BY_ID,
	async (idTeamLeader) => {
		if (idTeamLeader === undefined) {
			return null;
		}
		const response = await CommonService.getTeamLeaderById(idTeamLeader);
		return response.data;
	},
);

export const retrieveTeamLeaderInfoTypes = createAsyncThunk(
	RETRIEVE_TEAM_LEADER_INFO_TYPES,
	async () => {
		const response = await CommonService.getTeamLeaderInfoTypes();
		return response.data;
	},
);

export const retrieveContractMotiveEnd = createAsyncThunk(RETRIEVE_CONTRACTMOTIVEEND, async () => {
	const response = await CommonService.getContractMotiveEnd();
	return response.data;
});

export const retrieveVehicleTypes = createAsyncThunk(RETRIEVE_VEHICLETYPE, async () => {
	const response = await CommonService.getVehicleTypes();
	return response.data;
});

export const retrieveVehicleColors = createAsyncThunk(RETRIEVE_VEHICLECOLOR, async () => {
	const response = await CommonService.getVehicleColors();
	return response.data;
});

export const retrieveSpouseActivities = createAsyncThunk(RETRIEVE_SPOUSEACTIVITIES, async () => {
	const response = await CommonService.getSpouseActivities();
	return response.data;
});

export const retrieveTowns = createAsyncThunk(RETRIEVE_TOWNS, async () => {
	const response = await CommonService.getTowns();
	return response.data;
});

export const retrieveDepartments = createAsyncThunk(RETRIEVE_DEPARTMENTS, async () => {
	const response = await CommonService.getDepartments();
	return response.data;
});

export const retrieveWorkPermitApplieds = createAsyncThunk(
	RETRIEVE_WORKPERMITAPPLIEDS,
	async () => {
		const response = await CommonService.getWorkPermitApplieds();
		return response.data;
	},
);

export const retrieveTaxScales = createAsyncThunk(RETRIEVE_TAXSCALES, async () => {
	const response = await TaxService.getTaxScales();
	return response.data;
});

export const retrieveTaxChildCount = createAsyncThunk(RETRIEVE_TAXCHILDCOUNT, async () => {
	const response = await TaxService.getTaxChildCount();
	return response.data;
});

export const retrieveTaxCultTaxes = createAsyncThunk(RETRIEVE_TAXCULTTAXES, async () => {
	const response = await TaxService.getTaxCultTaxes();
	return response.data;
});

export const retrieveTaxCodesByCanton = createAsyncThunk(RETRIEVE_TAXCODES_BY_CANTON,
	async (cantonId) => {
	if (cantonId === undefined) {
		return null;
	}

	const response = await TaxService.getTaxCodesPerCanton(cantonId);
	return response.data;
});
