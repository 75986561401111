import { createAsyncThunk } from '@reduxjs/toolkit';
import { RETRIEVE_SHIFTS, UPDATE_SHIFT, RETRIEVE_SHIFT } from '../../constants/Shifts';
import ShiftService from '../../../services/ShiftService';

export const retrieveShifts = createAsyncThunk(RETRIEVE_SHIFTS, async (filtersProcessor) => {
	const response = await ShiftService.getShifts(filtersProcessor);
	return response.data;
});

export const updateShift = createAsyncThunk(UPDATE_SHIFT, async ({ idCourier, courierModel }) => {
	const response = await ShiftService.updateCourier(idCourier, courierModel);
	return response.data;
});

export const getShiftById = createAsyncThunk(RETRIEVE_SHIFT, async (idCourier) => {
	const response = await ShiftService.getCourierById(idCourier);
	return response.data;
});
