import React, { useRef, useContext, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { sitePaths } from '../site-paths';
import PopupContext from './PopUpContext';
import LocalStorageService from '../services/LocalStorageService';

export const IdleTimerUp = () => {
	/**
	 * Attribut d'affichage du popUp de confirmation
	 *  @type {*}
	 *  */
	const { showPopup } = useContext(PopupContext);

	const navigate = useNavigate();

	const idleTimerRef = useRef(null);

	const sessionTimeOutRef = useRef(null);

	const [timeOut] = useState(60 * 2); // en seconde

	const onCloseSession = () => {
		//  1. Redirect user to LOGIN
		navigate(`../${sitePaths.paths.login}`);

		//  2. Reset authentication from localstorage
		LocalStorageService.removeAll();
		clearTimeout(sessionTimeOutRef.current);
	};

	const onKeepSession = () => {
		clearTimeout(sessionTimeOutRef.current);
	};

	const handleIdle = () => {
		showPopup({
			title: 'Fermeture de la session utilisateur',
			message: `Vous êtes inatif depuis plus d'une heure, pouvez-vous confirmer pour garder la session active ?`,
		})
			.then(() => {
				onKeepSession();
			})
			.catch(() => {
				onCloseSession();
			});

		sessionTimeOutRef.current = setTimeout(onCloseSession, timeOut * 1000);
	};
	return (
		<div>
			<IdleTimer
				ref={idleTimerRef}
				timeout={60 * 60 * 1000} // 60 min * 60 sec * 1000 ms = 1 heure
				onIdle={handleIdle}
			/>
		</div>
	);
};

export default IdleTimerUp;
