import React from 'react';
import DashboardHeader from '../pages/common/Headers/DashboardHeader';
import CommonPageHeader from '../pages/common/Headers/CommonPageHeader';
import { sitePaths } from '../site-paths';

const headers = [
	{
		path: sitePaths.paths.dashboard,
		element: <DashboardHeader />,
		exact: true,
	},
	{
		path: sitePaths.paths.couriersList,
		element: <CommonPageHeader PageTitle='Couriers List' />,
		exact: true,
	},
	{
		path: sitePaths.paths.courier,
		element: <CommonPageHeader PageTitle='Courier' />,
		exact: true,
	},
	{
		path: sitePaths.paths.shiftsList,
		element: <CommonPageHeader PageTitle='Shifts List' />,
		exact: true,
	},
	{
		path: sitePaths.paths.administratorsList,
		element: <CommonPageHeader PageTitle='Administrators List' />,
		exact: true,
	},
	{
		path: sitePaths.paths.administrator,
		element: <CommonPageHeader PageTitle='Administrator' />,
		exact: true,
	},
	{
		path: sitePaths.paths.profileAdministrator,
		element: <CommonPageHeader PageTitle='Profile Administrator' />,
		exact: true,
	},
	{
		path: sitePaths.paths.profilesList,
		element: <CommonPageHeader PageTitle='Profiles' />,
		exact: true,
	},
	{
		path: sitePaths.paths.profile,
		element: <CommonPageHeader PageTitle='Profile' />,
		exact: true,
	},
	{
		path: sitePaths.paths.applicantsList,
		element: <CommonPageHeader PageTitle='Candidates' />,
		exact: true,
	},
	{
		path: sitePaths.paths.applicant,
		element: <CommonPageHeader PageTitle='Applicant' />,
		exact: true,
	},
	{
		path: sitePaths.paths.absencesList,
		element: <CommonPageHeader PageTitle='Absences' />,
		exact: true,
	},
	{
		path: sitePaths.paths.absence,
		element: <CommonPageHeader PageTitle='Absence' />,
		exact: true,
	},
	{
		path: sitePaths.paths.extrasmarketPlace,
		element: <CommonPageHeader PageTitle='MarketPlace' />,
		exact: true,
	},
	{
		path: sitePaths.paths.extrasTaken,
		element: <CommonPageHeader PageTitle='Visualization' />,
		exact: true,
	},
	{
		path: sitePaths.paths.extrasCreation,
		element: <CommonPageHeader PageTitle='Manage Extra' />,
		exact: true,
	},
	{
		path: sitePaths.paths.proceduresList,
		element: <CommonPageHeader PageTitle='Procedures' />,
		exact: true,
	},
	{
		path: sitePaths.paths.couriersGeolocs,
		element: <CommonPageHeader PageTitle='Geolocalization' />,
		exact: true,
	},
	{
		path: sitePaths.paths.couriersPerformances,
		element: <CommonPageHeader PageTitle='Performances' />,
		exact: true,
	},
	{
		path: sitePaths.paths.agendas,
		element: <CommonPageHeader PageTitle='Agendas' />,
		exact: true,
	},
	{
		path: sitePaths.paths.procedure,
		element: <CommonPageHeader PageTitle='Procedure' />,
		exact: true,
	},
	{
		path: sitePaths.paths.commercialAdditionalItemsList,
		element: <CommonPageHeader PageTitle='Additional Items List' />,
		exact: true,
	},
	{
		path: sitePaths.paths.commercialOrdersList,
		element: <CommonPageHeader PageTitle='Orders List' />,
		exact: true,
	},
	{
		path: sitePaths.paths.commercialOrder,
		element: <CommonPageHeader PageTitle='Order' />,
		exact: true,
	},
	{
		path: sitePaths.paths.commercialPostalCodesList,
		element: <CommonPageHeader PageTitle='Postal Codes List' />,
		exact: true,
	},
	{
		path: sitePaths.paths.commercialProductsList,
		element: <CommonPageHeader PageTitle='Products List' />,
		exact: true,
	},
	{
		path: sitePaths.paths.commercialVehiclesList,
		element: <CommonPageHeader PageTitle='Vehicles List' />,
		exact: true,
	},
	{
		path: sitePaths.paths.commercialProductsEdit,
		element: <CommonPageHeader PageTitle='Product' />,
		exact: true,
	},
	{
		path: sitePaths.paths.commercialAdditionalItemEdit,
		element: <CommonPageHeader PageTitle='Additional Item' />,
		exact: true,
	},
	{
		path: sitePaths.paths.commercialWeightsList,
		element: <CommonPageHeader PageTitle='Weights' />,
		exact: true,
	},

	{
		path: sitePaths.paths.commercialVolumesList,
		element: <CommonPageHeader PageTitle='Volumes' />,
		exact: true,
	},
	{
		path: sitePaths.paths.commercialPostalCodesEdit,
		element: <CommonPageHeader PageTitle='Postal Code' />,
		exact: true,
	},
];

export default headers;
