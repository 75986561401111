export const RETRIEVE_NATIONALITIES = 'commons/nationalities';
export const RETRIEVE_CIVILSTATUS = 'commons/civilstatus';
export const RETRIEVE_DRIVINGLICENSES = 'commons/drivinglicenses';
export const RETRIEVE_ACTIVITIES = 'couriers/activities';
export const RETRIEVE_OTHERSACTIVITIES = 'couriers/othersactivities';
export const RETRIEVE_CONTRACTSTATUS = 'couriers/contractstatus';
export const RETRIEVE_SITUATIONS = 'couriers/sitautions';
export const RETRIEVE_CONTRACTCLIENT = 'couriers/contractclient';
export const RETRIEVE_CONTRACTTYPE = 'couriers/contracttype';
export const RETRIEVE_CONTRACTREASONEND = 'couriers/contractreasonend';
export const RETRIEVE_SHIFTTYPE = 'shifts/shifttypes';
export const RETRIEVE_RIGHTTYPE = 'profiles/righttypes';
export const RETRIEVE_RIGHT = 'profiles/rights';
export const RETRIEVE_ABSENCETYPE = 'absences/absencetypes';
export const RETRIEVE_DOCUMENTTYPE = 'documents/documenttypes';
export const RETRIEVE_DOCUMENTSTATUS = 'documents/documentstatus';
export const RETRIEVE_ABSENCESTATUS = 'absences/absencestatus';
export const RETRIEVE_COURIERAPPLYSTATUS = 'couriers/courierApplystatus';
export const RETRIEVE_EXTRATYPE = 'commons/extratypes';
export const RETRIEVE_COLORS = 'colors/names';
export const RETRIEVE_PROCEDURES_NAMES = 'procedures/names';
export const RETRIEVE_TEAMLEADERS = 'couriers/teamleader';
export const RETRIEVE_STATUTINVITATION = 'agendas/statutinvitation';
export const RETRIEVE_TYPEINVITATION = 'agendas/typeinvitation';
export const RETRIEVE_SWISSCANTON = 'couriers/swisscanton';
export const RETRIEVE_PROCEDURES_STATUS = 'procedures/statuts';
export const RETRIEVE_TEAMLEADER_BY_ID = 'couriers/teamleader_by_id';
export const RETRIEVE_TEAM_LEADER_INFO_TYPES = 'common/teamleader_info_types';
export const RETRIEVE_CONTRACTMOTIVEEND = 'couriers/contractmotiveend';
export const RETRIEVE_VEHICLETYPE = 'couriers/vehicletypes';
export const RETRIEVE_VEHICLECOLOR = 'couriers/vehiclecolors';
export const RETRIEVE_SPOUSEACTIVITIES = 'spouses/activities';
export const RETRIEVE_TOWNS = 'commons/towns';
export const RETRIEVE_DEPARTMENTS = 'commons/departments';
export const RETRIEVE_WORKPERMITAPPLIEDS = 'commons/workpermitapplieds';
export const RETRIEVE_TAXSCALES = 'couriers/taxscales';
export const RETRIEVE_TAXCHILDCOUNT = 'couriers/childcount';
export const RETRIEVE_TAXCULTTAXES = 'couriers/culttaxes';
export const RETRIEVE_TAXCODES_BY_CANTON = 'couriers/taxcodes_by_canton';
