import { createAsyncThunk } from '@reduxjs/toolkit';
import { RETRIEVE_ORDER, RETRIEVE_ORDERS } from '../../../constants/Commercial/Orders';
import OrderService from '../../../../services/Commercial/OrderService';

export const retrieveOrders = createAsyncThunk(RETRIEVE_ORDERS, async (filtersProcessor) => {
	const response = await OrderService.getOrders(filtersProcessor);
	return response.data;
});

export const retrieveOrder = createAsyncThunk(RETRIEVE_ORDER, async (id) => {
	const response = await OrderService.getOrderById(id);
	return response.data;
});
