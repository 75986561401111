import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { logInWithEmailAndPassword } from '../../../services/AuthenticationService';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import { updateAbilityWithToken } from '../../../security/ability';

/**
 * Méthode de validation des saisies email et mot de passe
 *
 * @param {*} values
 * @return {*}
 */
const validate = (values) => {
	const errors = {};

	if (!values.email) {
		errors.email = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	} else {
		errors.email = '';
	}

	if (!values.password) {
		errors.password = 'Required';
	} else if (values.password.length < 3) {
		errors.password = 'Must be 3 characters or more';
	} else {
		errors.password = '';
	}

	return errors;
};

const LoginHeader = () => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Bienvenue,</div>
			<div className='text-center h4 text-muted mb-5'>Ouvrez une session pour continuer!</div>
		</>
	);
};

/**
 * Page de connexion d'un utilisateur
 *
 * @return {*}
 * */
const Login = () => {
	const { darkModeStatus } = useDarkMode();

	const { t } = useTranslation('translation');

	/**
	 * Attribut contenant les informations du coursier
	 * @type {*}
	 *  */
	const [loading, setLoading] = useState(false);

	/**
	 * Les informations du connexion
	 *  @type {*}
	 * */
	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validate,
	});

	/**
	 * Attribut de navigation
	 * @type {*}
	 * */
	const navigate = useNavigate();

	/**
	 * Cette méthode permet de se connecter
	 *
	 */
	const handleOnClick = async () => {
		setLoading(true);
		try {
			const loginData = await logInWithEmailAndPassword(
				formik.values.email,
				formik.values.password,
			);
			updateAbilityWithToken(loginData.accessToken);
			formik.resetForm();
			setLoading(false);
			navigate('/', { replace: true });
		} catch (error) {
			setLoading(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Echec de la connexion</span>
				</span>,
				"L'email ou le mot de passe saisi est incorrecte.",
				'danger',
			);
		}
	};

	return (
		<PageWrapper
			title='Connexion'
			className={classNames({ 'bg-success': true, 'bg-info': false })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-lo10-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col-12'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={false}
												className='rounded-1 w-100'
												size='lg'>
												Connexion
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader />

								{/* Spinner pendant le chargement des données */}
								{loading && (
									<div className='col-12 pb-6'>
										<Button color='secondary' isLight>
											<Spinner
												color='secondary'
												inButton
												isGrow
												size='3rem'
											/>
											{t('Authentification')}
										</Button>
									</div>
								)}

								<form className='row g-4'>
									<div>
										<div className='col-12 pb-2'>
											<FormGroup id='email' isFloating label='Votre email'>
												<Input
													autoComplete='username'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={formik.touched.email}
													invalidFeedback={formik.errors.email}
													value={formik.values.email}
												/>
											</FormGroup>
										</div>
										<div className='col-12 pb-2'>
											<FormGroup
												id='password'
												isFloating
												label='Mot de passe'>
												<Input
													type='password'
													autoComplete='password'
													onKeyPress={(event) => {
														if (event.key === 'Enter') handleOnClick();
													}}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={formik.touched.password}
													invalidFeedback={formik.errors.password}
													value={formik.values.password}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<Button
												color='warning'
												className='w-100 py-3'
												onClick={handleOnClick}>
												Se Connecter
											</Button>
										</div>
									</div>
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': false,
									'link-dark': true,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': false,
									'link-dark': true,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
