import React, { createContext, useState, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

const initialPopup = {
	title: '',
	message: '',
	visible: false,
	onDismiss: null,
	onConfirm: null,
	okButton: 'Confirm',
	cancelButton: 'Cancel',
};

const initRef = {
	resolve: null,
	reject: null,
};

const PopupContext = createContext({});

export const PopupProvider = ({ children }) => {
	const [popup, setPopup] = useState(initialPopup);
	const resolver = useRef(initRef);

	const showPopup = useCallback(
		(args) => {
			setPopup({ ...initialPopup, visible: true, ...args });

			return new Promise((resolve, reject) => {
				resolver.current = { resolve, reject };
			});
		},
		[setPopup],
	);

	const onDismiss = useCallback(() => {
		setPopup({ ...popup, visible: false });
		resolver?.current?.reject();
	}, [popup]);

	const onConfirm = useCallback(() => {
		setPopup({ ...popup, visible: false });
		resolver?.current?.resolve();
	}, [popup]);

	const PopUpCOntexValue = useMemo(
		() => ({
			showPopup,
			onDismiss,
			onConfirm,
			popup,
		}),
		[onConfirm, onDismiss, popup, showPopup],
	);

	return <PopupContext.Provider value={PopUpCOntexValue}>{children}</PopupContext.Provider>;
};

PopupProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default PopupContext;
