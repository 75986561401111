import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PopupContext from './PopUpContext';
import Button from '../components/bootstrap/Button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalTitle,
	ModalHeader,
} from '../components/bootstrap/Modal';

export const Popup = () => {
	const { t } = useTranslation('translation');

	const { popup, onDismiss, onConfirm } = useContext(PopupContext);

	return (
		<Modal
			id='modalParent'
			isCentered
			isOpen={popup.visible}
			setIsOpen={() => onDismiss()}
			size='lg'
			isScrollable>
			<ModalHeader>
				<ModalTitle id='modalTitleParent'>{popup.title}</ModalTitle>
			</ModalHeader>
			<ModalBody>{popup.message}</ModalBody>
			<ModalFooter>
				<Button icon='Cancel' color='dark' onClick={() => onDismiss()}>
					{t(popup.cancelButton)}
				</Button>
				<Button icon='ConfirmationNumber' color='dark' onClick={() => onConfirm()}>
					{t(popup.okButton)}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default Popup;
