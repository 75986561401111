import axiosInstance from '../configurations/AxiosConfig';

const getAbsences = async (filtersProcessor) => {
	const request = `/Absences/Retrieve`;
	return axiosInstance.post(request, filtersProcessor);
};

const getAbsenceById = async (absenceValidationId) => {
	const request = `/Absences/${absenceValidationId}`;
	return axiosInstance.get(request);
};

const getAbsenceSummury = async (courierId, start, end) => {
	const request = `/Absences/${courierId}/AbsenceSummury?start=${start}&end=${end}`;
	return axiosInstance.get(request);
};

const sendRejectionAbsenceToCourieryById = async (absenceValidationId) => {
	const request = `/Absences/${absenceValidationId}/RejectAbsence`;
	return axiosInstance.get(request);
};

const sendAcceptationAbsenceToCourieryById = async (absenceValidationId) => {
	const request = `/Absences/${absenceValidationId}/AcceptAbsence`;
	return axiosInstance.get(request);
};

const createExtrasBaseOnAbsenceById = async (absenceValidationId) => {
	const request = `/Absences/${absenceValidationId}/CreateExtra`;
	return axiosInstance.get(request);
};

const sendCancelationAbsenceToCourieryById = async (absenceValidationId) => {
	const request = `/Absences/${absenceValidationId}/CancelAbsence`;
	return axiosInstance.get(request);
};

const deleteAbsenceById = async (absenceValidationId) => {
	const request = `/Absences/${absenceValidationId}`;
	return axiosInstance.delete(request);
};

const createAbsence = async (absenceModel) => {
	const request = `/Absences`;
	return axiosInstance.post(request, absenceModel, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

const updateAbsence = async (absenceModel) => {
	const request = `/Absences`;
	return axiosInstance.put(request, absenceModel);
};

const exportAbsences = async (searchExportFilter) => {
	const request = `/Absences/Export`;
	return axiosInstance.post(request, searchExportFilter, { responseType: 'blob' });
};

const AbsenceService = {
	getAbsences,
	getAbsenceById,
	getAbsenceSummury,
	sendRejectionAbsenceToCourieryById,
	sendAcceptationAbsenceToCourieryById,
	sendCancelationAbsenceToCourieryById,
	createExtrasBaseOnAbsenceById,
	deleteAbsenceById,
	createAbsence,
	exportAbsences,
	updateAbsence,
};

export default AbsenceService;
