import axiosInstance from '../../configurations/AxiosConfig.Commercial';

const getOrders = async (filtersProcessor) => {
	const request = `/v1/Orders`;
	return axiosInstance.get(request, { params: filtersProcessor });
};

const getOrderById = async (id) => {
	const request = `/v1/Orders/${id}`;
	return axiosInstance.get(request);
};

const OrderService = {
	getOrders,
	getOrderById,
};

export default OrderService;
