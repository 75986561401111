import { createSlice } from '@reduxjs/toolkit';
import { retrieveShifts } from '../../actions/Shifts';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	shifts: [],
	totalShifts: 0,
};

/**
 * Le slide ou reducers de la gestion des shifts
 */
export const shiftsSlice = createSlice({
	name: 'shifts',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrieveShifts.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveShifts.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.shifts = [...action.payload.data];
			state.totalShifts = action.payload.count;
		},
		[retrieveShifts.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const shiftsSelector = (state) => state.shifts;

/**
 * Export du reducer
 */
const { reducer } = shiftsSlice;
export default reducer;
