import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	CREATE_COURIER,
	RETRIEVE_COURIERS,
	RETRIEVE_COURIERAPPLIES,
	RETRIEVE_COURIER,
	RETRIEVE_COURIER_MESSAGE,
	RETRIEVE_COURIER_PLANNING,
	RETRIEVE_COURIER_ABSENCE,
	RETRIEVE_COURIER_EXTRA,
	UPDATE_COURIER,
} from '../../constants/Couriers';
import CourierService from '../../../services/CourierService';
import CourierApplyService from '../../../services/CourierApplyService';

export const createCourier = createAsyncThunk(CREATE_COURIER, async ({ courierModel }) => {
	const response = await CourierService.createCourier(courierModel);
	return response.data;
});

export const retrieveCouriers = createAsyncThunk(RETRIEVE_COURIERS, async (filtersProcessor) => {
	const response = await CourierService.getCouriers(filtersProcessor);
	return response.data;
});

export const retrieveCourierApplies = createAsyncThunk(
	RETRIEVE_COURIERAPPLIES,
	async (filtersProcessor) => {
		const response = await CourierApplyService.getCourierApplies(filtersProcessor);
		return response.data;
	},
);

export const retrieveCourierMessages = createAsyncThunk(
	RETRIEVE_COURIER_MESSAGE,
	async ({ courierId, filtersProcessor, filter }) => {
		const response = await CourierService.getCourierMessages(
			courierId,
			filtersProcessor,
			filter,
		);
		return response.data;
	},
);

export const retrieveCourierPlannings = createAsyncThunk(
	RETRIEVE_COURIER_PLANNING,
	async ({ courierId, type, date }) => {
		const response = await CourierService.getCourierPlannings(courierId, type, date);
		return response.data;
	},
);

export const retrieveCourierAbsences = createAsyncThunk(
	RETRIEVE_COURIER_ABSENCE,
	async ({ courierId, type, date }) => {
		const response = await CourierService.getCourierAbsences(courierId, type, date);
		return response.data;
	},
);

export const retrieveCourierExtras = createAsyncThunk(
	RETRIEVE_COURIER_EXTRA,
	async ({ courierId, type, date }) => {
		const response = await CourierService.getCourierExtras(courierId, type, date);
		return response.data;
	},
);

export const updateCourier = createAsyncThunk(
	UPDATE_COURIER,
	async ({ idCourier, courierModel }) => {
		const response = await CourierService.updateCourier(idCourier, courierModel);
		return response.data;
	},
);

export const getCourierById = createAsyncThunk(RETRIEVE_COURIER, async (idCourier) => {
	const response = await CourierService.getCourierById(idCourier);
	return response.data;
});
