import axiosInstance from '../../configurations/AxiosConfig.Commercial';

const getVehicles = async (filtersProcessor) => {
	const request = `/v1/Vehicles`;
	return axiosInstance.get(request, { params: filtersProcessor });
};

const VehicleService = {
	getVehicles,
};

export default VehicleService;
