import { createSlice } from '@reduxjs/toolkit';
import {
	retrieveCivilStatus,
	retrieveNationalities,
	retrieveDrivingLicenses,
	retrievePrincipalActivities,
	retrieveOthersActivities,
	retrieveContractStatus,
	retrieveContractClient,
	retrieveContractType,
	retrieveContractReasonEnd,
	retrieveShiftType,
	retrieveRightType,
	retrieveRight,
	retrieveAbsenceType,
	retrieveDocumentType,
	retrieveDocumentStatus,
	retrieveAbsenceStatus,
	retrieveCourierApplyStatus,
	retrieveExtraTypes,
	retrieveColors,
	retrieveProceduresNames,
	retrieveTeamLeaders,
	retrieveSituations,
	retrieveStatutInvitations,
	retrieveTypeInvitations,
	retrieveSwissCantons,
	retrieveStatutProcedures,
	retrieveTeamLeaderById,
	retrieveTeamLeaderInfoTypes,
	retrieveContractMotiveEnd,
	retrieveVehicleTypes,
	retrieveVehicleColors,
	retrieveSpouseActivities,
	retrieveTowns,
	retrieveDepartments,
	retrieveWorkPermitApplieds,
	retrieveTaxScales,
	retrieveTaxChildCount,
	retrieveTaxCultTaxes,
	retrieveTaxCodesByCanton,
} from '../../actions/Commons';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	nationalities: [],
	civilStatus: [],
	drivingLicenses: [],
	activities: [],
	otheractivities: [],
	swissCantons: [],
	contractStatus: [],
	contractClients: [],
	contractTypes: [],
	contractReasonEnd: [],

	shiftTypes: [],
	rightTypes: [],
	rights: [],
	absenceTypes: [],
	documentTypes: [],
	absenceStatus: [],
	documentStatus: [],
	courierApplyStatus: [],
	extraTypes: [],
	colors: [],
	proceduresNames: [],
	teamLeaders: [],
	situations: [],
	statutInvitations: [],
	typeInvitations: [],
	proceduresStatusNames: [],
	teamLeader: null,
	teamLeaderInfoTypes: [],
	contractMotiveEnd: [],
	vehicleTypes: [],
	vehicleColors: [],
	spouseActivities: [],
	towns: [],
	departments: [],
	workPermitApplieds: [],

	taxScales: [],
	taxChildCount: [],
	taxCultTaxes: [],
	taxCodes: null,
};

/**
 * Le slide ou reducers de la gestion des coursiers
 */
export const commonSlice = createSlice({
	name: 'commons',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrieveCivilStatus.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.civilStatus = [...action.payload];
		},
		[retrieveNationalities.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.nationalities = [...action.payload];
		},
		[retrieveDrivingLicenses.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.drivingLicenses = [...action.payload];
		},
		[retrievePrincipalActivities.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.activities = [...action.payload];
		},
		[retrieveOthersActivities.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.otheractivities = [...action.payload];
		},
		[retrieveContractStatus.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.contractStatus = [...action.payload];
		},
		[retrieveContractClient.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.contractClients = [...action.payload];
		},
		[retrieveContractType.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.contractTypes = [...action.payload];
		},
		[retrieveContractReasonEnd.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.contractReasonEnd = [...action.payload];
		},
		[retrieveShiftType.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.shiftTypes = [...action.payload];
		},
		[retrieveRightType.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.rightTypes = [...action.payload];
		},
		[retrieveRight.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.rights = [...action.payload];
		},
		[retrieveAbsenceType.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.absenceTypes = [...action.payload];
		},
		[retrieveDocumentType.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.documentTypes = [...action.payload];
		},
		[retrieveDocumentStatus.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.documentStatus = [...action.payload];
		},
		[retrieveAbsenceStatus.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.absenceStatus = [...action.payload];
		},
		[retrieveCourierApplyStatus.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.courierApplyStatus = [...action.payload];
		},
		[retrieveExtraTypes.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.extraTypes = [...action.payload];
		},
		[retrieveColors.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.colors = [...action.payload];
		},
		[retrieveProceduresNames.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.proceduresNames = [...action.payload];
		},
		[retrieveTeamLeaders.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.teamLeaders = [...action.payload];
		},
		[retrieveSituations.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.situations = [...action.payload];
		},
		[retrieveStatutInvitations.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.statutInvitations = [...action.payload];
		},
		[retrieveTypeInvitations.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.typeInvitations = [...action.payload];
		},
		[retrieveSwissCantons.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.swissCantons = [...action.payload];
		},
		[retrieveStatutProcedures.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.proceduresStatusNames = [...action.payload];
		},
		[retrieveTeamLeaderById.fulfilled]: (state, action) => {
			state.teamLeader = { ...action.payload };
		},
		[retrieveTeamLeaderInfoTypes.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.teamLeaderInfoTypes = [...action.payload];
		},
		[retrieveContractMotiveEnd.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.contractMotiveEnd = [...action.payload];
		},
		[retrieveVehicleTypes.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.vehicleTypes = [...action.payload];
		},
		[retrieveVehicleColors.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.vehicleColors = [...action.payload];
		},
		[retrieveSpouseActivities.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.spouseActivities = [...action.payload];
		},
		[retrieveTowns.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.towns = [...action.payload];
		},
		[retrieveDepartments.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.departments = [...action.payload];
		},
		[retrieveWorkPermitApplieds.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.workPermitApplieds = [...action.payload];
		},
		[retrieveTaxScales.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.taxScales = [...action.payload];
		},
		[retrieveTaxChildCount.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.taxChildCount = [...action.payload];
		},
		[retrieveTaxCultTaxes.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.taxCultTaxes = [...action.payload];
		},
		[retrieveTaxCodesByCanton.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.taxCodes = {
				...action.payload,
			};
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const commonsSelector = (state) => state.commons;

/**
 * Export du reducer
 */
const { reducer } = commonSlice;
export default reducer;
