import axiosInstance from '../configurations/AxiosConfig';

const getColorsOptions = async () => {
	const request = `/Colors/List`;
	return axiosInstance.get(request);
};

const getColorByHex = async (colorHex) => {
	const request = `/Colors?colorHex=${colorHex}`;
	return axiosInstance.get(request);
};

const createCourierColor = async (courierColorModel) => {
	const request = `/Colors/Courier`;
	return axiosInstance.post(request, courierColorModel);
};

const deleteCourierColor = async (courierColorId) => {
	const request = `/Colors/Courier/${courierColorId}`;
	return axiosInstance.delete(request);
};

const ColorService = {
	createCourierColor,
	getColorByHex,
	getColorsOptions,
	deleteCourierColor,
};

export default ColorService;
