const setItem = (key, value) => {
	value = JSON.stringify(value);
	localStorage.setItem(key, value);
	return true;
};

const getItem = (key) => {
	const value = localStorage.getItem(key);
	try {
		return JSON.parse(value);
	} catch (e) {
		return null;
	}
};

const removeItem = (key) => {
	localStorage.removeItem(key);
};

const removeAll = () => {
	LocalStorageService.removeItem('auth_user');
	LocalStorageService.removeItem('pageFilters');
};

const LocalStorageService = {
	setItem,
	getItem,
	removeItem,
	removeAll,
};

export default LocalStorageService;
