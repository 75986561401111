export const sitePaths = {
	paths: {
		dashboard: '/',
		login: 'auth-pages/login',
		page404: 'auth-pages/404',
		couriersList: 'managements/couriers',
		courier: 'managements/couriers/:id',
		addCourier: 'managements/couriers/add',
		couriersGeolocs: 'managements/location/couriers',
		couriersPerformances: 'managements/performance/couriers',
		applicantsList: 'managements/applicants',
		applicant: 'managements/applicants/:id',
		administratorsList: 'administrations/administrators',
		administrator: 'administrations/administrators/:id',
		profileAdministrator: 'administrations/administrators/profile/:id',
		profilesList: 'administrations/profiles',
		profile: 'administrations/profiles/:id',
		shiftsList: 'organisations/shifts',
		absencesList: 'organisations/absences',
		absence: 'organisations/absences/:id',
		extrasmarketPlace: 'extras/marketplace',
		extrasTaken: 'extras/visualization',
		extrasCreation: 'extras/creation',
		proceduresList: 'administrative-hr/procedures',
		procedure: 'administrative-hr/procedures/:id',
		workPermits: 'administrative-hr/workPermits',
		agendas: 'administrative-hr/agendas',
		commercialAdditionalItemsList: 'commercial/additionalItems',
		commercialAdditionalItemEdit: 'commercial/additionalItems/:id',
		commercialOrdersList: 'commercial/orders',
		commercialOrder: 'commercial/orders/:id',
		commercialPostalCodesList: 'commercial/postalCodes',
		commercialPostalCodesEdit: 'commercial/postalCodes/:id',
		commercialProductsList: 'commercial/products',
		commercialProductsEdit: 'commercial/products/:id',
		commercialVehiclesList: 'commercial/vehicles',
		commercialWeightsList: 'commercial/weights',
		commercialVolumesList: 'commercial/volumes',
	},
};

export default sitePaths;
