import { createSlice } from '@reduxjs/toolkit';
import { retrieveAbsences, getAbsenceById } from '../../actions/Absences';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	absences: [],
	totalAbsences: 0,
};

/**
 * Le slide ou reducers de la gestion des absences
 */
export const absencesSlice = createSlice({
	name: 'absences',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrieveAbsences.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveAbsences.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.absences = [...action.payload.data];
			state.totalAbsences = action.payload.count;
		},
		[retrieveAbsences.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
		[getAbsenceById.fulfilled]: (state, action) => {
			return action.payload;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const absencesSelector = (state) => state.absences;

/**
 * Export du reducer
 */
const { reducer } = absencesSlice;
export default reducer;
