import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	RETRIEVE_ADDITIONAL_ITEMS,
	RETRIEVE_ADDITIONAL_ITEM,
} from '../../../constants/Commercial/AdditionalItems';
import AdditionalItemService from '../../../../services/Commercial/AdditionalItemService';

export const retrieveAdditionalItems = createAsyncThunk(
	RETRIEVE_ADDITIONAL_ITEMS,
	async (filtersProcessor) => {
		const response = await AdditionalItemService.getAdditionalItems(filtersProcessor);
		return response.data;
	},
);

export const retrieveAdditionalItem = createAsyncThunk(RETRIEVE_ADDITIONAL_ITEM, async (id) => {
	const response = await AdditionalItemService.getAdditionalItemById(id);
	return {
		...response.data,
		price: response.data.price / 100,
		translationEn: response.data.languages.find((l) => l.cultureCode === 'en')?.value,
		translationFr: response.data.languages.find((l) => l.cultureCode === 'fr')?.value,
	};
});
