import { createAsyncThunk } from '@reduxjs/toolkit';
import { RETRIEVE_ABSENCES, RETRIEVE_ABSENCE } from '../../constants/Absences';
import AbsenceService from '../../../services/AbsenceService';

export const retrieveAbsences = createAsyncThunk(RETRIEVE_ABSENCES, async (filtersProcessor) => {
	const response = await AbsenceService.getAbsences(filtersProcessor);
	return response.data;
});

export const getAbsenceById = createAsyncThunk(RETRIEVE_ABSENCE, async (absenceId) => {
	const response = await AbsenceService.getAbsenceById(absenceId);
	return response.data;
});
