import { createSlice } from '@reduxjs/toolkit';
import { retrievePostalCode, flushPostalCode } from '../../../actions/Commercial/PostalCodes';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	postalCode:{
		postalCodeId: 0,
		town: "",
		postCode: "",
		districtId: 0,
		isPickUpLocation: false,
		isDeliveryLocation: false,
	},
};

/**
 * Le slice ou reducers de la gestion d'un postalCode
 */
export const postalCodeSlice = createSlice({
	name: 'postalCode',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrievePostalCode.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrievePostalCode.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.postalCode = action.payload;
		},
		[retrievePostalCode.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
		[flushPostalCode.type]: () => ({...initialState})
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const postalCodeSelector = (state) => state.commercialPostalCode;

/**
 * Export du reducer
 */
const { reducer } = postalCodeSlice;
export default reducer;
