import axiosInstance from '../configurations/AxiosConfig';

const getProfiles = async (filtersProcessor, filter) => {
	const request = `/Profiles/Retrieve?filter=${filter}`;
	return axiosInstance.post(request, filtersProcessor);
};

const getProfilesList = async () => {
	const request = `/Profiles`;
	return axiosInstance.get(request);
};

const getOrganizationList = async () => {
	const request = `/Profiles/Organizations`;
	return axiosInstance.get(request);
};

const getProfileById = async (profileId) => {
	const request = `/Profiles/${profileId}`;
	return axiosInstance.get(request);
};

const deleteProfileById = async (profileId) => {
	const request = `/Profiles/${profileId}`;
	return axiosInstance.delete(request);
};

const createProfile = async (profileModel) => {
	const request = `/Profiles`;
	return axiosInstance.post(request, profileModel);
};

const updateProfile = async (profileId, profileModel) => {
	const request = `/Profiles/${profileId}`;
	return axiosInstance.put(request, profileModel);
};

const ProfileService = {
	getProfiles,
	getProfilesList,
	getOrganizationList,
	getProfileById,
	deleteProfileById,
	createProfile,
	updateProfile,
};

export default ProfileService;
