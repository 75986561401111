import { createSlice } from '@reduxjs/toolkit';
import { retrieveProducts } from '../../../actions/Commercial/Products';

/**
 *  Etat initial
 */
export const productsInitialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	products: [],
	totalProducts: 0,
};

/**
 * Le slice ou reducers de la gestion des products
 */
export const productsSlice = createSlice({
	name: 'products',
	initialState: productsInitialState,

	// Extra reducers
	extraReducers: {
		[retrieveProducts.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveProducts.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.products = [...action.payload.items];
			state.totalProducts = action.payload.totalCount;
		},
		[retrieveProducts.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const productsSelector = (state) => state.commercialProducts;

/**
 * Export du reducer
 */
const { reducer } = productsSlice;
export default reducer;
