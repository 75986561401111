import axiosInstance from '../configurations/AxiosConfig';

const getProcedures = async (filtersProcessor) => {
	const request = `Procedures/Retrieve`;
	return axiosInstance.post(request, filtersProcessor);
};

const getListProcedures = async (languageCode) => {
	const request = `/Procedures/ListName?languageCode=${languageCode}`;
	return axiosInstance.get(request);
};

const getOptionsProcedures = async (languageCode) => {
	const request = `/Procedures/Options?languageCode=${languageCode}`;
	return axiosInstance.get(request);
};

const getProceduresByCourierId = async (courierId, languageCode) => {
	const request = `/Procedures/Courier?courierId=${courierId}&languageCode=${languageCode}`;
	return axiosInstance.get(request);
};

const getProcedureCourierById = async (idProcedureCourier, languageCode) => {
	const request = `/Procedures/${idProcedureCourier}?languageCode=${languageCode}`;
	return axiosInstance.get(request);
};

const createProcedureCourier = async (procedureCourier) => {
	const request = `/Procedures`;
	return axiosInstance.post(request, procedureCourier);
};

const updateProcedureCourier = async (idProcedureCourier, procedureCourierModel) => {
	const request = `/Procedures/${idProcedureCourier}`;
	return axiosInstance.put(request, procedureCourierModel);
};

const makeRequestProcedureCourier = async (idProcedureCourier, languageCode) => {
	const request = `/Procedures/MakeRequest?procedureCourierId=${idProcedureCourier}&languageCode=${languageCode}`;
	return axiosInstance.put(request);
};

const archiveProcedureCourier = async (idProcedureCourier, languageCode) => {
	const request = `/Procedures/Archive?procedureCourierId=${idProcedureCourier}&languageCode=${languageCode}`;
	return axiosInstance.put(request);
};

const deleteProcedureCourier = async (idProcedureCourier, languageCode) => {
	const request = `/Procedures?procedureCourierId=${idProcedureCourier}&languageCode=${languageCode}`;
	return axiosInstance.delete(request);
};

const finishProcedureCourier = async (idProcedureCourier, languageCode) => {
	const request = `/Procedures/Finish?procedureCourierId=${idProcedureCourier}&languageCode=${languageCode}`;
	return axiosInstance.put(request);
};

const initiateMultipleProcedureCourier = async (idsProcedureCourier, languageCode) => {
	const request = `/Procedures/InitiateProcedures?languageCode=${languageCode}`;
	return axiosInstance.post(request, idsProcedureCourier);
};

const finishMultipleProcedureCourier = async (idsProcedureCourier, languageCode) => {
	const request = `/Procedures/FinishProcedures?languageCode=${languageCode}`;
	return axiosInstance.post(request, idsProcedureCourier);
};

const archiveMultipleProcedureCourier = async (idsProcedureCourier, languageCode) => {
	const request = `/Procedures/ArchiveProcedures?languageCode=${languageCode}`;
	return axiosInstance.post(request, idsProcedureCourier);
};

const deleteMultipleProcedureCourier = async (idsProcedureCourier, languageCode) => {
	const request = `/Procedures/DeleteProcedures?languageCode=${languageCode}`;
	return axiosInstance.post(request, idsProcedureCourier);
};

const getCourierHasProceduresByCourierId = async (courierId) => {
    const request = `/Procedures/Courier/${courierId}/HasProcedures`;
    return axiosInstance.get(request);
};

const ProcedureService = {
	getProcedures,
	getListProcedures,
	getProceduresByCourierId,
	createProcedureCourier,
	getOptionsProcedures,
	makeRequestProcedureCourier,
	archiveProcedureCourier,
	deleteProcedureCourier,
	updateProcedureCourier,
	getProcedureCourierById,
	finishProcedureCourier,
	initiateMultipleProcedureCourier,
	finishMultipleProcedureCourier,
	archiveMultipleProcedureCourier,
	deleteMultipleProcedureCourier,
	getCourierHasProceduresByCourierId,
};

export default ProcedureService;
