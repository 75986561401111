import { createSlice } from '@reduxjs/toolkit';
import { retrieveAdditionalItem } from '../../../actions/Commercial/AdditionalItems';

/**
 *  Etat initial
 */
export const additionalItemInitialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	additionalItem: {
		additionalItemId: 0,
		name: 0,
		value: 0,
		price: 0,
		additionalItemTypeId: 0,
		translationEn: '',
		translationFr: '',
	},
};

/**
 * Le slice ou reducers de la gestion d'un produit
 */
export const additionalItemSlice = createSlice({
	name: 'additionalItem',
	initialState: additionalItemInitialState,
	extraReducers: {
		[retrieveAdditionalItem.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveAdditionalItem.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.additionalItem = action.payload;
		},
		[retrieveAdditionalItem.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const additionalItemSelector = (state) => state.commercialAdditionalItem;

/**
 * Export du reducer
 */
const { reducer } = additionalItemSlice;
export default reducer;
