import { createSlice } from '@reduxjs/toolkit';
import {
	createAdministrator,
	retrieveAdministrators,
	updateAdministrator,
	getAdministratorById,
} from '../../actions/Administrators';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	administrators: [],
	totalAdministrators: 0,
};

/**
 * Le slide ou reducers de la gestion des administrateurs
 */
export const administratorsSlice = createSlice({
	name: 'administrators',
	initialState,
	// Extra reducers
	extraReducers: {
		[createAdministrator.fulfilled]: (state, action) => {
			state.push(action.payload);
		},
		[retrieveAdministrators.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveAdministrators.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.administrators = [...action.payload.data];
			state.totalAdministrators = action.payload.count;
		},
		[retrieveAdministrators.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
		[updateAdministrator.fulfilled]: (state, action) => {
			const index = state.findIndex((admin) => admin.id === action.payload.id);
			state[index] = {
				...state[index],
				...action.payload,
			};
		},
		[getAdministratorById.fulfilled]: (state, action) => {
			return action.payload;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const administratorsSelector = (state) => state.administrators;

/**
 * Export du reducer
 */
const { reducer } = administratorsSlice;
export default reducer;
