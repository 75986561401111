import { createSlice } from '@reduxjs/toolkit';
import {
	retrieveExtrasMarketplace,
	retrieveExtrasTaken,
	retrieveExtrasStatistics,
} from '../../actions/Extras';

/**
 *  Etat initial
 */
export const initialState = {
	isLoading: false,
	isError: false,
	isSuccess: false,
	marketPlaces: [],
	extrasTaken: [],
	extrasStatistics: null,
	totalExtras: 0,
};

/**
 * Le slide ou reducers de la gestion des extras
 */
export const extrasSlice = createSlice({
	name: 'extras',
	initialState,

	// Extra reducers
	extraReducers: {
		[retrieveExtrasMarketplace.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveExtrasMarketplace.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.marketPlaces = [...action.payload.data];
			state.totalExtras = action.payload.count;
		},
		[retrieveExtrasMarketplace.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},

		[retrieveExtrasTaken.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveExtrasTaken.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.extrasTaken = action.payload;
			state.totalExtras = action.payload.listCouriersExtras.length;
		},
		[retrieveExtrasTaken.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},

		[retrieveExtrasStatistics.pending]: (state) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		},
		[retrieveExtrasStatistics.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = true;
			state.extrasStatistics = action.payload;
		},
		[retrieveExtrasStatistics.rejected]: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		},
	},
});

/**
 * Export du selecteur
 * @param {*} state
 * @returns
 */
export const extrasSelector = (state) => state.extras;

/**
 * Export du reducer
 */
const { reducer } = extrasSlice;
export default reducer;
